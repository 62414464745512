import React, { useContext } from 'react';
import { StyleEditorContext } from '../../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import getValueFromPath from '../../../../../../../../../../utils/HelperFunctions/getValueFromPath';
import stylePanelStyles from '../../styles.module.scss';

interface AlignItemsChangerProps {
    path: string[];
}

const AlignItemsChanger = ({ path }: AlignItemsChangerProps): JSX.Element => {
    const { selectedElement, handleStyleChange } = useContext(StyleEditorContext);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        handleStyleChange({
            style: 'alignItems',
            path,
            value: e.target.value,
        });
    };

    return (
        <label className={stylePanelStyles['inner-label']} htmlFor='alignItems'>
            Align Items:
            <select
                id='alignItems'
                value={getValueFromPath(selectedElement?.styles, path)?.alignItems || 'stretch'}
                onChange={handleChange}
            >
                <option value='stretch'>Stretch</option>
                <option value='flex-start'>Start</option>
                <option value='flex-end'>End</option>
                <option value='center'>Center</option>
            </select>
        </label>
    );
};

export default AlignItemsChanger;
