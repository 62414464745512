import React, { useEffect, useRef } from 'react';
import { useWatch } from 'react-hook-form';

const PopupHtmlRenderer = () => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const popupHtml = useWatch({ name: 'popupHtml' });

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.innerHTML = popupHtml;
        }
    }, [popupHtml]);

    return <div ref={containerRef} style={{ display: 'flex', flexDirection: 'column' }} />;
};

export default PopupHtmlRenderer;
