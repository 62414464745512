export const DEFAULT_AFFILIATE_RATE: string = '25%'; // must match same variable from server
export const MINIMUM_PAYMENT_THRESHOLD: string = '$10 USD';
export const MINIMUM_QUALIFICATION_TIME_QTY: number = 3;
export const MINIMUM_QUALIFICATION_TIME_UNIT: string = 'month';
export const MINIMUM_QUALIFICATION_TIME_SINGULAR: string = `${MINIMUM_QUALIFICATION_TIME_QTY} ${MINIMUM_QUALIFICATION_TIME_UNIT}`;
export const MINIMUM_QUALIFICATION_TIME_PLURAL: string = `${MINIMUM_QUALIFICATION_TIME_QTY} ${MINIMUM_QUALIFICATION_TIME_UNIT}s`;
export const COOKIE_DURATION: string = '1 year';
export const DEFAULT_CURRENCY_SYMBOL: string = '£';
export const HOSTNAME: string = 'popuphawk.com';
export const PROTOCOL: string = 'https';
// export const SITE_URL: string = 'http://localhost:3000'; // @todo: change back to `${PROTOCOL}://www.${HOSTNAME}`;
export const SITE_URL: string = `${PROTOCOL}://www.${HOSTNAME}`; // for local dev convenience, could use 'http://localhost:3000'
export const NO_MODAL_OVERLAY: { [key: string]: unknown } = {
    overlay: {
        background: 'unset',
        top: 'unset',
    },
};
export const DEFAULT_ITEMS_PER_PAGE = 10;
export enum Plan {
    FREE = 1,
    LITE = 2,
    STANDARD = 3,
    PRO = 4,
    EXPERT = 5,
}
export type PlanKey = keyof typeof Plan;
export const SESSION_CAPS = {
    [Plan.FREE]: 500, // n.b. if this changes, need to change the default value of current_monthly_sessions on the users table
    [Plan.LITE]: 10000,
    [Plan.STANDARD]: 30000,
    [Plan.PRO]: 100000,
    [Plan.EXPERT]: 250000,
};

export enum SubscriptionStatus {
    INITIAL = 1,
    ACTIVE = 2,
    IN_ARREARS = 3,
    RESTRICTED = 4,
    MANUALLY_CANCELLED = 5,
}
export type SubscriptionStatusKey = keyof typeof SubscriptionStatus;
export type Plans = 'FREE' | 'LITE' | 'STANDARD' | 'PRO' | 'EXPERT';
export type PaidPlans = 'LITE' | 'STANDARD' | 'PRO' | 'EXPERT';
export const PAID_PLANS_ARRAY: PaidPlans[] = ['LITE', 'STANDARD', 'PRO', 'EXPERT'];
export const MAX_TEST_SESSIONS: any = {
    [Plan.FREE]: 400,
    [Plan.LITE]: 4000,
    [Plan.PRO]: 10000,
    // @todo: perhaps add platinum option later - ref PLAT2523
};
export enum TARGET_DEVICES {
    MOBILES = 1,
    TABLETS = 2,
    DESKTOPS = 3,
}
export const TRACKING_SCRIPT_PATH = `${window.location.origin}/scripts/tracking/track.js`;
// prod
export enum PLAN_PRICE_IDS {
    LITE = 'price_1Q4i4ACemj7XfGvcxgF6j2Ga',
    STANDARD = 'price_1Q4i4XCemj7XfGvcgUJt5eO9',
    PRO = 'price_1Q4i4qCemj7XfGvc6XGg5aDL',
    EXPERT = 'price_1Q4i56Cemj7XfGvcxPdydkSv',
}
// testing
// export enum PLAN_PRICE_IDS {
//     LITE = 'price_1Q4hvNCemj7XfGvc3FInkEgD',
//     STANDARD = 'price_1Q4hvhCemj7XfGvcjznPctG4',
//     PRO = 'price_1Q4hw0Cemj7XfGvcc0c2Pey7',
//     EXPERT = 'price_1Q4hwICemj7XfGvc0gEVSs2e',
// }
/*
    PRICE PLAN IDS
    ==============

    LITE PLAN IDS:
    - test: price_1Q4hvNCemj7XfGvc3FInkEgD
    - real: price_1Q4i4ACemj7XfGvcxgF6j2Ga
    STANDARD PLAN IDS:
    - test: price_1Q4hvhCemj7XfGvcjznPctG4
    - real: price_1Q4i4XCemj7XfGvcgUJt5eO9
    PRO PLAN IDS:
    - test: price_1Q4hw0Cemj7XfGvcc0c2Pey7
    - real: price_1Q4i4qCemj7XfGvc6XGg5aDL
    EXPERT PLAN IDS:
    - test: price_1Q4hwICemj7XfGvc0gEVSs2e
    - real: price_1Q4i56Cemj7XfGvcxPdydkSv
*/
export const PAYMENT_SUCCESS_REDIRECT_PATH: string = '/payment-success';
export const PAYMENT_CANCEL_REDIRECT_PATH: string = '/payment-cancelled';
// note: ensure PAYMENT_SUCCESS_REDIRECT_PATH & PAYMENT_CANCEL_REDIRECT_PATH correspond with BE global variables
export enum PRICES_MONTHLY {
    LITE = '£34.99',
    STANDARD = '£64.99',
    PRO = '£119.99',
    EXPERT = '£249.99',
}
export const PAYMENT_METHOD_UPDATE_SUCCESS_REDIRECT_URL: string = '/payment-method-update-success';
export const PAYMENT_METHOD_UPDATE_CANCEL_REDIRECT_URL: string = '/payment-method-update-cancelled';
export enum AVATAR_VALUES { // @todo: check when going to prod as these values may be WRONG
    OTHER_GOAL = '6e4972c3-0f98-4e10-830e-c97b6436a20d', // ab_testing_goals table
    OTHER_INDUSTRY = 'c3202e8b-03df-4b52-b5d0-51b3d72cb4b8', // industries table
    OTHER_PRIMARY_OBJECTIVE = 'd847f478-4998-4a29-8f76-6746db31a200', // website_objectives table
    OTHER_JOB_CATEGORY = '37d4c20a-75f3-40d7-8b36-4a1b5d45f9c8', // job_categories table
    OTHER_COMPETITOR = '008f5156-12a3-449d-a611-a27a8f50ff58', // previous_platforms table
}
export enum MAX_VARIANTS {
    FREE = 1,
    BEGINNER = 2,
    PRO = 4,
    // @todo: perhaps add a platinum plan or something later - ref PLAT2523
}
export enum VIEWPORTS {
    MOBILES = 768,
    TABLETS = 1024,
    DESKTOPS = 1440,
}
// e.g. can use with const isMobile = useIsDevice(VIEWPORTS.MOBILES);
export const DEFAULT_FONT_FAMILY: string = 'Arial';

export const DEFAULT_CLOSE_BUTTON_STYLES: React.CSSProperties = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '24px',
    fontWeight: 700,
    color: '#ff0000',
    zIndex: 999999999999999,
};
