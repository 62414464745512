import { useState, useEffect } from 'react';

const useIsDevice = (breakpoint: number) => {
    const [isDevice, setIsDevice] = useState(window.innerWidth <= breakpoint);

    useEffect(() => {
        const handleResize = () => {
            setIsDevice(window.innerWidth <= breakpoint);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [breakpoint]);

    return isDevice;
};

export default useIsDevice;
