import React, { useContext } from 'react';
import { StyleEditorContext } from '../../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import getValueFromPath from '../../../../../../../../../../utils/HelperFunctions/getValueFromPath';
import stylePanelStyles from '../../styles.module.scss';

interface HeightChangerProps {
    path: string[];
}

const HeightChanger = ({ path }: HeightChangerProps): JSX.Element => {
    const { selectedElement, handleStyleChange } = useContext(StyleEditorContext);

    return (
        <label className={stylePanelStyles['inner-label']} htmlFor='height'>
            Height (px):
            <input
                id='height'
                type='number'
                min='0'
                value={parseInt(getValueFromPath(selectedElement?.styles, path)?.height || '0', 10)}
                onChange={(e) =>
                    handleStyleChange({
                        style: 'height',
                        path,
                        value: `${parseInt(e.target.value, 10)}px`,
                    })
                }
            />
        </label>
    );
};

export default HeightChanger;
