/*
    This function takes a React.CSSProperties object and returns a string of CSS properties.
    It is used to generate CSS string from React.CSSProperties object.
    (Generate the CSS string from the styles object)
    */
const generateCssString = (styles: React.CSSProperties | undefined) => {
    if (!styles) return '';
    return Object.entries(styles)
        .map(([key, value]) => `${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${value};`)
        .join(' ');
};

export default generateCssString;
