import React from 'react';
import { useDroppable } from '@dnd-kit/core';

interface DropZoneProps {
    children: React.ReactElement;
}

// Use React.forwardRef to pass the ref to the child element
const DropZone = React.forwardRef<HTMLDivElement, DropZoneProps>(({ children }, ref) => {
    const { setNodeRef } = useDroppable({ id: 'droppable-area' });

    return React.cloneElement(children, {
        // Combine setNodeRef and the forwarded ref
        ref: (node: HTMLDivElement) => {
            setNodeRef(node); // Set the droppable ref
            if (typeof ref === 'function') {
                ref(node); // Call the forwarded ref if it's a function
            } else if (ref) {
                (ref as React.MutableRefObject<HTMLDivElement | null>).current = node; // Set the forwarded ref if it's an object
            }
        },
    });
});

export default DropZone;
