import React, { createContext, FC, useCallback, useMemo, useRef, useState } from 'react';
import { StyleEditorContextType, StyleEditorProviderProps, StyleChangeParams } from './types';
import {
    DndElement,
    updateElementFn,
} from '../../components/account/campaigns/create/CreateCampaignStageTwo/PopupBuilder/DndBuilder/types';
import { Tab } from '../../components/account/campaigns/create/Shared/types';
import { DEFAULT_CLOSE_BUTTON_STYLES } from '../../utils/global-variables';

export const StyleEditorContext = createContext<StyleEditorContextType>({
    elements: [],
    setElements: () => {},
    selectedElement: null,
    updateElement: () => {},
    handleStyleChange: () => () => {},
    bodyStyles: {},
    setBodyStyles: () => {},
    closeButtonStyles: {},
    setCloseButtonStyles: () => {},
    previewMode: true,
    setPreviewMode: () => {},
    activeTab: 'elements',
    setActiveTab: () => {},
    codeContainerRef: { current: null },
    setTransformedHTML: () => {},
});

export const StyleEditorProvider: FC<StyleEditorProviderProps> = ({ children }) => {
    const [elements, setElements] = useState<DndElement[]>([]);
    const [bodyStyles, setBodyStyles] = useState<React.CSSProperties>({
        backgroundColor: '#ffffff',
        borderRadius: '0px',
        borderColor: '#000000',
        padding: '10px',
    });
    const [closeButtonStyles, setCloseButtonStyles] = useState<React.CSSProperties>(
        DEFAULT_CLOSE_BUTTON_STYLES
    );
    const [previewMode, setPreviewMode] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<Tab>('elements');
    const codeContainerRef = useRef<HTMLDivElement | null>(null);

    const updateElement: updateElementFn = useCallback((id, newProperties) => {
        setElements((prev) => prev.map((el) => (el.id === id ? { ...el, ...newProperties } : el)));
    }, []);

    const selectedElement = useMemo(() => elements.find((el) => el.selected) || null, [elements]);

    const handleStyleChange = useCallback(
        ({ style, path, value }: StyleChangeParams) => {
            if (!selectedElement) return;

            // Clone the current styles of the selected element
            const updatedStyles = { ...selectedElement.styles };

            // Traverse the path to reach the desired nested style object
            let currentLevel: Record<string, any> = updatedStyles;
            for (let i = 0; i < path.length - 1; i++) {
                currentLevel = currentLevel[path[i]] = currentLevel[path[i]] || {};
            }

            // Update the specific style property with the new value
            currentLevel[path[path.length - 1]] = {
                ...currentLevel[path[path.length - 1]],
                [style]: value,
            };

            // Update the element with the new styles
            updateElement(selectedElement.id, { styles: updatedStyles });
        },
        [selectedElement, updateElement]
    );

    const setTransformedHTML = useCallback(
        (
            container: HTMLDivElement | null,
            setValue: (key: string, value: string) => void
        ): void => {
            if (!container) return;

            const clone = container.cloneNode(true) as HTMLDivElement;

            const temporaryParents = clone.querySelectorAll('[data-temporary-parent="true"]');
            temporaryParents.forEach((parent) => {
                const children = Array.from(parent.childNodes);
                children.forEach((child) => parent.parentNode?.insertBefore(child, parent));
                parent.parentNode?.removeChild(parent);
            });

            const temporaryElements = clone.querySelectorAll('[data-temporary-element="true"]');
            temporaryElements.forEach((element) => element.parentNode?.removeChild(element));

            const closeButton = clone.querySelector('.popup-hawk-close-btn');
            if (closeButton) {
                closeButton.setAttribute('onclick', 'window.popupHawk?.closePopup()');
            }

            setValue('popupHtml', clone.innerHTML);
        },
        []
    );

    return (
        <StyleEditorContext.Provider
            value={{
                elements,
                setElements,
                selectedElement,
                handleStyleChange,
                updateElement,
                bodyStyles,
                setBodyStyles,
                closeButtonStyles,
                setCloseButtonStyles,
                previewMode,
                setPreviewMode,
                activeTab,
                setActiveTab,
                codeContainerRef,
                setTransformedHTML,
            }}
        >
            {children}
        </StyleEditorContext.Provider>
    );
};
