import React from 'react';
import styles from './styles.module.scss';
import useIsDevice from '../../../../hooks/other/useIsDevice';

const HeroContent = () => {
    const isMediumViewport = useIsDevice(1000);

    if (isMediumViewport) return null;

    return (
        <div className={styles.outer}>
            <div
                className={`${styles.container} ${styles.s1} ${styles.s2} ${styles.s3} ${styles.s4} ${styles.s5}`}
            >
                <button className={styles.close} />
                <h2>Subscribe for 10% Off!</h2>
                <p>Don't miss out on our discounted offer on all orders.</p>
                <input type='email' placeholder='Email Address' />
                <button className={styles.submit}>Get 10% Off</button>
            </div>
        </div>
    );
};

export default HeroContent;
