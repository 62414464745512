import React, { useContext } from 'react';
import { StyleEditorContext } from '../../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import getValueFromPath from '../../../../../../../../../../utils/HelperFunctions/getValueFromPath';
import stylePanelStyles from '../../styles.module.scss';

interface FlexDirectionChangerProps {
    path: string[];
}

const FlexDirectionChanger = ({ path }: FlexDirectionChangerProps): JSX.Element => {
    const { selectedElement, handleStyleChange } = useContext(StyleEditorContext);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        handleStyleChange({
            style: 'flexDirection',
            path,
            value: e.target.value,
        });
    };

    return (
        <label className={stylePanelStyles['inner-label']}>
            Element Direction:
            <select
                id='flexDirection'
                value={getValueFromPath(selectedElement?.styles, path)?.flexDirection || 'row'}
                onChange={handleChange}
            >
                <option value='row'>Horizontal</option>
                <option value='column'>Vertical</option>
            </select>
        </label>
    );
};

export default FlexDirectionChanger;
