import React, { useCallback, useContext } from 'react';
import { StyleEditorContext } from '../../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import getValueFromPath from '../../../../../../../../../../utils/HelperFunctions/getValueFromPath';
import stylePanelStyles from '../../styles.module.scss';

interface BorderStyleChangerProps {
    path: string[];
}

const BorderStyleChanger = ({ path }: BorderStyleChangerProps): JSX.Element => {
    const { selectedElement, handleStyleChange } = useContext(StyleEditorContext);

    const handleBorderRadiusChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const borderRadius = e.target.value + 'px';
            handleStyleChange({
                style: 'borderRadius',
                path,
                value: borderRadius,
            });
        },
        [handleStyleChange, path]
    );

    const handleBorderThicknessChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const thickness = e.target.value + 'px';
            handleStyleChange({
                style: 'borderWidth',
                path,
                value: thickness,
            });
        },
        [handleStyleChange, path]
    );

    return (
        <>
            <label className={stylePanelStyles['inner-label']} htmlFor='borderRadius'>
                Border Radius (px):
                <input
                    id='borderRadius'
                    type='number'
                    min='0'
                    max='50'
                    value={parseInt(
                        getValueFromPath(selectedElement?.styles, path)?.borderRadius || '0',
                        10
                    )}
                    onChange={handleBorderRadiusChange}
                />
            </label>
            <label className={stylePanelStyles['inner-label']} htmlFor='borderThickness'>
                Border Thickness (px):
                <input
                    id='borderThickness'
                    type='number'
                    min='0'
                    max='20'
                    value={parseInt(
                        getValueFromPath(selectedElement?.styles, path)?.borderWidth || '0',
                        10
                    )}
                    onChange={handleBorderThicknessChange}
                />
            </label>
            <label className={stylePanelStyles['inner-label']} htmlFor='borderStyle'>
                Border Style:
                <select
                    id='borderStyle'
                    value={getValueFromPath(selectedElement?.styles, path)?.borderStyle || 'none'}
                    onChange={(e) =>
                        handleStyleChange({
                            style: 'borderStyle',
                            path,
                            value: e.target.value,
                        })
                    }
                >
                    <option value='none'>None</option>
                    <option value='solid'>Solid</option>
                    <option value='dotted'>Dotted</option>
                    <option value='dashed'>Dashed</option>
                    <option value='double'>Double</option>
                </select>
            </label>
            <label className={stylePanelStyles['inner-label']} htmlFor='borderColor'>
                Border Colour:
                <input
                    id='borderColor'
                    className={stylePanelStyles['colour-picker']}
                    type='color'
                    value={
                        getValueFromPath(selectedElement?.styles, path)?.borderColor || '#000000'
                    }
                    onChange={(e) =>
                        handleStyleChange({
                            style: 'borderColor',
                            path,
                            value: e.target.value,
                        })
                    }
                />
            </label>
        </>
    );
};

export default BorderStyleChanger;
