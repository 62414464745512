import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { CodeField } from '../../../../../../default/Form/Form';
import styles from './styles.module.scss';

const CodePanel = () => {
    // @todo non-mvp: use a package to validate the HTML when clicking proceed

    const { setValue, watch, setError, clearErrors } = useFormContext();

    const popupHtml = watch('popupHtml');
    const popupCss = watch('popupCss');
    const popupJs = watch('popupJs');

    // const handleHtmlEditorChange = (value: string): void => {
    //     setValue('popupHtml', value);

    //     if (value.trim() === '') {
    //         setError('popupHtml', {
    //             type: 'manual',
    //             message: 'HTML content is required.',
    //         });
    //     } else {
    //         clearErrors('popupHtml');
    //     }
    // };

    const handleCssEditorChange = useCallback(
        (value: string): void => {
            setValue('popupCss', value);
        },
        [setValue]
    );

    const handleJsEditorChange = useCallback(
        (value: string): void => {
            setValue('popupJs', value);
        },
        [setValue]
    );

    useEffect(() => {
        // Check if popupHtml is empty on component mount
        if (popupHtml?.trim() === '') {
            setError('popupHtml', {
                type: 'manual',
                message: 'HTML content is required.',
            });
        } else {
            clearErrors('popupHtml');
        }
    }, [popupHtml, setError, clearErrors]);

    return (
        <div>
            {/* <CodeField
                alias='Enter HTML'
                title='popupHtml'
                language='html'
                onChange={handleHtmlEditorChange}
                defaultValue={popupHtml}
            /> */}
            <CodeField
                alias='Enter CSS'
                title='popupCss'
                language='css'
                onChange={handleCssEditorChange}
                defaultValue={popupCss}
                cls={styles['label-text']}
            />
            <CodeField
                alias='Enter JavaScript'
                title='popupJs'
                language='javascript'
                onChange={handleJsEditorChange}
                defaultValue={popupJs}
                cls={styles['label-text']}
            />
        </div>
    );
};

export default CodePanel;
