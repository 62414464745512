import React, { useEffect, useCallback, useContext } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DndElement } from '../types';
import { faArrowsAlt, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import cssStyles from './styles.module.scss';
import generateCssString from '../../../../../../../../utils/HelperFunctions/generateCssString';
import { DEFAULT_FONT_FAMILY } from '../../../../../../../../utils/global-variables';
import { StyleEditorContext } from '../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';

interface Props {
    element: DndElement;
    onElementClick: () => void;
}

const SortableItem = ({ element, onElementClick }: Props): JSX.Element => {
    const { setElements } = useContext(StyleEditorContext);
    const { id, type, html, styles = {}, btn = {} } = element;
    const { text = 'Change Button Text', hyperlink = '', onClickClose } = btn || {};
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    useEffect(() => {
        // Set hyperlink to null when onClickClose is true
        if (onClickClose) {
            setElements((prevElements: DndElement[]) =>
                prevElements.map((el: DndElement) =>
                    el.id === id
                        ? {
                              ...el,
                              btn: { ...el.btn, hyperlink: null },
                          }
                        : el
                )
            );
        }
    }, [onClickClose, id, setElements]);

    const deleteElement = useCallback(() => {
        setElements((prevElements: DndElement[]) =>
            prevElements.filter((element: DndElement) => element.id !== id)
        );
    }, [id, setElements]);

    const style: React.CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const isForm = type === 'email';

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            data-temporary-parent='true'
            data-temporary-element='true'
            // className={cssStyles['overall-container']}
            className={'overall-container'}
        >
            {!isForm && styles?.element?.regular && (
                <style data-styles>{`#${id}{${generateCssString(styles.element.regular)}}`}</style>
            )}
            {/* <div className={cssStyles['button-container']} data-temporary-element='true'> */}
            <div className={'button-container'} data-temporary-element='true'>
                <div
                    onClick={(e) => e.preventDefault()}
                    data-temporary-element='true'
                    className='button-container-child'
                    {...listeners}
                >
                    <FontAwesomeIcon icon={faArrowsAlt} aria-label='drag' />
                </div>
                <div
                    data-temporary-element='true'
                    onClick={(e) => {
                        e.preventDefault();
                        onElementClick();
                    }}
                    className='button-container-child'
                >
                    <FontAwesomeIcon icon={faPencilAlt} aria-label='edit' />
                </div>
                <div
                    onClick={() => deleteElement()}
                    data-temporary-element='true'
                    className='button-container-child'
                >
                    <FontAwesomeIcon icon={faTrash} aria-label='delete' />
                </div>
            </div>
            {type === 'rich-text' && (
                <div
                    id={id}
                    className='ph-text'
                    style={{
                        fontFamily: element.html?.styles?.fontFamily || DEFAULT_FONT_FAMILY,
                        fontSize: `${element.html?.styles?.fontSize || 16}px`,
                        color: element.html?.styles?.color || '#000000',
                        textAlign: element.html?.styles?.textAlign || 'left',
                        opacity: element.html?.styles?.opacity || 1,
                    }}
                    onClick={onElementClick}
                    dangerouslySetInnerHTML={{ __html: html?.inner || '' }}
                />
            )}
            {type === 'hr' && <hr id={id} onClick={onElementClick} />}
            {type === 'button' && (
                <a
                    id={id}
                    href={hyperlink || '#'}
                    onClick={onElementClick}
                    data-on-click-close={onClickClose ? 'true' : undefined}
                >
                    {text}
                </a>
            )}
            {isForm && (
                <>
                    {styles?.formElements?.form && (
                        <style data-styles>{`#${id}{${generateCssString(
                            styles.formElements.form
                        )}}`}</style>
                    )}
                    {styles?.formElements && (
                        <style data-styles>{`
                            #${id} label[for='email'] {
                                ${generateCssString(styles.formElements.emailInputLabel)}
                            }
                            #${id} input[type='email'] {
                                ${generateCssString(styles.formElements.emailInput)}
                            }
                            #${id} button[type='submit'] {
                                display: inline-block;
                                ${generateCssString(styles.formElements.submitButton)}
                            }
                        `}</style>
                    )}
                    {/* N.B.: this div will be replaced with a form tag */}
                    <div data-temporary-form-replica='true' id={id} onClick={onElementClick}>
                        {/* <label for="first_name">Name:</label><input type="text" id="first_name" name="first_name" required> */}
                        <label htmlFor='email'>Email:</label>
                        <input
                            type='email'
                            id='email'
                            name='email'
                            placeholder='Enter email'
                            required
                        />
                        <button type='submit' className='popup-submit-btn'>
                            {btn.text || 'Submit'}
                        </button>
                    </div>
                    <p className='popup-hawk-submission hidden'>
                        Thank you - form submitted successfully.
                    </p>
                </>
            )}
        </div>
    );
};

export default React.memo(SortableItem);
