import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import styles from './styles.module.scss';
import defaultStyles from '../../../../../../../css/default.module.scss';
import SortableItem from './SortableItem/SortableItem';
import DropZone from './DropZone/DropZone';
import { DndElement } from './types';
import { StyleEditorContext } from '../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import { useFormContext, useWatch } from 'react-hook-form';
import generateStyleProperties from '../../../../../../../utils/HelperFunctions/generateStyleProperties';
import PopupHtmlRenderer from './PopupHtmlRenderer/PopupHtmlRenderer';
import ShadowWrapper from '../../ShadowWrapper';

const DndBuilder = () => {
    const { setValue } = useFormContext();
    const userDefinedCss = useWatch({
        name: 'popupCss',
    });
    const {
        elements,
        setElements,
        bodyStyles,
        closeButtonStyles,
        previewMode,
        setPreviewMode,
        setActiveTab,
        codeContainerRef,
        setTransformedHTML,
    } = useContext(StyleEditorContext);
    const previewText = previewMode ? 'Continue Editing' : 'Show Preview';
    // Generate style content for the style tag
    const innerStyleContent = useMemo(() => generateStyleProperties(bodyStyles), [bodyStyles]);
    const closeButtonStyleContent = useMemo(
        () => generateStyleProperties(closeButtonStyles),
        [closeButtonStyles]
    );

    const handleElementClick = useCallback(
        (id: string) => {
            setActiveTab('styles');
            setElements((prevElements: DndElement[]) =>
                prevElements.map((el) =>
                    el.id === id ? { ...el, selected: true } : { ...el, selected: false }
                )
            );
        },
        [setActiveTab, setElements]
    );

    useEffect(() => {
        setTransformedHTML(codeContainerRef.current, setValue);
    }, [
        setTransformedHTML,
        bodyStyles,
        elements,
        userDefinedCss,
        closeButtonStyles,
        codeContainerRef,
        setValue,
    ]);

    return (
        <div className={styles.container}>
            <ShadowWrapper>
                {/* <div id?={`popup-hawk-container-outer ${styles.containerOuter}`}> */}
                <div id={'popup-hawk-container-outer'}>
                    <style>
                        {`
                        *:not(style):not(.button-container):not(.button-container *):not(.ph-text *){all:initial}

                        #popup-hawk-container-outer {
                            position: relative; /* different */
                            /*
                                below is used in script but excluded here:
                                position: fixed;
                                top: 50%;
                                left: 50%;
                            */
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            box-sizing: border-box;
                            flex-direction: column;
                            margin: 0;
                            padding: 0;
                            overflow: hidden;
                            width: auto;
                            /* transform: translate(-50%, -50%); used in main script only */
                            /* z-index: 999999999999999; */
                            z-index: 999; /* just for the previewer */
                            max-width: 100vw;

                             @media (min-width: 768px) {
                                padding: 3em;
                            }

                            #phci {
                                box-sizing: border-box;
                                position: relative; // may need to apply within script also?

                                @media (min-width: 768px) {
                                    width: 90vw;
                                    max-width: 400px;
                                }
                            }
                        }`}
                    </style>

                    {!previewMode && (
                        // note: using !important to prevent user overriding with their own CSS
                        <style data-temp='true'>
                            {`
                        /* Editor button styles */
                        #popup-hawk-container-outer .overall-container {
                            /*padding: 1em;*/
                            border: 1px solid rgba(252, 185, 107, 0.8) !important;
                            /*margin-bottom: 1em;*/
                            position: relative;
                            cursor: pointer;
                            position: relative;
                            display: flex !important;
                            flex-direction: column;
                        }

                    #popup-hawk-container-outer .overall-container:hover {
                            background-color: rgba(252, 185, 107, 0.8);
                        }

                    #popup-hawk-container-outer .button-container {
                            background: rgba(252, 185, 107, 0.8) !important;
                            position: absolute !important;
                            top: -1px;
                            left: -1px;
                            display: flex !important;
                            align-items: center;
                            justify-content: center;
                            transform: translateY(-100%);
                            padding: 0.25em 0.5em;
                            border-top-left-radius: 0.25em;
                            border-top-right-radius: 0.25em;
                        }

                        #popup-hawk-container-outer .button-container > div {
                            width: 1em;
                            height: 1em;
                        }

                        #popup-hawk-container-outer .button-container > div:not(:first-child) {
                            margin-left: 0.5em;
                        }
                            
                        #popup-hawk-container-outer .button-container-child {
                            display: flex !important;
                        }
                    `}
                        </style>
                    )}

                    <DropZone ref={codeContainerRef as React.RefObject<HTMLDivElement>}>
                        {/* Pass the ref to DropZone */}
                        <div
                            data-code-container='true'
                            // className={`popup-hawk-container-inner ${styles.containerInner}`}
                            id={'phci'}
                        >
                            {/* The ref will be combined and set on this div */}
                            {previewMode ? (
                                <PopupHtmlRenderer />
                            ) : (
                                <>
                                    <SortableContext
                                        items={elements.map((element: DndElement) => element.id)}
                                        strategy={verticalListSortingStrategy}
                                    >
                                        <button
                                            className='popup-hawk-close-btn'
                                            // onclick='window.popupHawk.closePopup()' added by setTransformedHTML
                                        />
                                        {elements.map((element: DndElement) => (
                                            <SortableItem
                                                key={element.id}
                                                element={element}
                                                onElementClick={() =>
                                                    handleElementClick(element.id)
                                                }
                                            />
                                        ))}
                                    </SortableContext>
                                    <style>{`#phci{${innerStyleContent}}#phci .popup-hawk-close-btn{${closeButtonStyleContent}}#phci .popup-hawk-close-btn::before{content:"✕";}#phci .hidden{display:none}${userDefinedCss}`}</style>
                                </>
                            )}
                        </div>
                    </DropZone>
                </div>
            </ShadowWrapper>
            <button
                onClick={(e) => {
                    e.preventDefault();
                    setPreviewMode(!previewMode);
                    setTransformedHTML(codeContainerRef.current, setValue);
                }}
                className={`${defaultStyles.btnRed} ${styles.previewButton}`}
            >
                {previewText}
            </button>
        </div>
    );
};

export default DndBuilder;
