import React from 'react';
import ButtonEditor from '../ButtonEditor/ButtonEditor';
import EmailInputEditor from './EmailInputEditor/EmailInputEditor';
import FormElementEditor from './FormElementEditor/FormElementEditor';
import EmailLabelEditor from './EmailLabelEditor/EmailLabelEditor';
import stylePanelStyles from '../../styles.module.scss';

const FormEditor = () => {
    return (
        <div className={stylePanelStyles['editor-container']}>
            <h2>Form Editor</h2>
            <FormElementEditor />
            <EmailInputEditor />
            <EmailLabelEditor />
            <ButtonEditor
                path={['formElements', 'submitButton']}
                isFormButton={true}
                title='Submit Button'
                largeHeading={false}
            />
        </div>
    );
};

export default FormEditor;
