/**
 * Function replacing data-on-click-close="true" attribute on all elements with onclick=window.popupHawk?.closePopup()
 * @param html - HTML string
 * @returns HTML string with updated elements
 */

const addOnClickClose = (html: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const elements = doc.querySelectorAll('[data-on-click-close="true"]');
    elements.forEach((element) => {
        element.setAttribute('onclick', 'window.popupHawk?.closePopup()');
        element.removeAttribute('data-on-click-close');
    });

    return doc.body.innerHTML;
};

export default addOnClickClose;
