import React, { useContext, useState, useEffect } from 'react';
import BodyEditor from './components/BodyEditor/BodyEditor';
import ElementEditor from './components/ElementEditor/ElementEditor';
import CloseButtonEditor from './components/CloseButtonEditor/CloseButtonEditor';
import { StyleEditorContext } from '../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import styles from './styles.module.scss';

const StylePanel = () => {
    const { elements, selectedElement, setElements } = useContext(StyleEditorContext);
    const [activeEditor, setActiveEditor] = useState<string | null>(null);

    useEffect(() => {
        if (selectedElement) {
            setActiveEditor(null);
        }
    }, [selectedElement]);

    const handleItemClick = (editor: string): void => {
        setElements((prevElements) => prevElements.map((el) => ({ ...el, selected: false }))); // deselect elements
        setActiveEditor(editor);
    };

    const editingCloseBtn = activeEditor === 'closeButton';
    const editingBody = activeEditor === 'body';

    return (
        <div className={styles.container}>
            <p>Select what you would like to edit:</p>
            <ul>
                <li onClick={() => handleItemClick('body')}>
                    Body {editingBody && <span className={styles.editing}>(editing)</span>}
                </li>
                <li onClick={() => handleItemClick('closeButton')}>
                    Close Button{' '}
                    {editingCloseBtn && <span className={styles.editing}>(editing)</span>}
                </li>
            </ul>
            {selectedElement ? (
                <ElementEditor />
            ) : editingBody ? (
                <BodyEditor />
            ) : editingCloseBtn ? (
                <CloseButtonEditor />
            ) : (
                elements.length > 0 && <p>To edit an element, click on its pencil icon.</p>
            )}
        </div>
    );
};

export default StylePanel;
