/**
 * Retrieves the value from a nested object based on the provided path.
 *
 * @param obj - The object to retrieve the value from.
 * @param path - An array of strings representing the path to the desired value.
 * @returns The value at the specified path, or undefined if the path does not exist.
 *
 * @example
 * const element: DndElement = {
 *   id: '1',
 *   type: 'button',
 *   styles: {
 *     element: {
 *       regular: {
 *         backgroundColor: '#ffffff',
 *         borderRadius: '5px',
 *       },
 *     },
 *   },
 * };
 * const path = ['element', 'regular', 'backgroundColor'];
 * const value = getValueFromPath(element.styles, path); // '#ffffff'
 */
const getValueFromPath = (obj: any, path: string[]) => {
    return path.reduce((acc, key) => acc?.[key], obj);
};

export default getValueFromPath;
