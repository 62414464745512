import React, { useCallback, useContext, useEffect } from 'react';
import defaultStyles from '../../../../../css/default.module.scss';
import { InfoAreaWrap } from '../../../../../components/default/Cards/Cards';
import { FormProvider, useForm } from 'react-hook-form';
import { Back } from '../../../../../components/default/Navigation/Navigation';
import { yupResolver } from '@hookform/resolvers/yup';
import { createCampaignSchema } from '../../../../../schemas/CreateCampaignSchema';
import { withPage } from '../../../../../components/layout/Page/Page';
import { createCampaignMeta } from '../../../../misc/MetaTags';
import { useCreateCampaignStage } from '../../../../../hooks/campaigns/useCreateCampaignStage/useCreateCampaignStage';
import CreateCampaignStageOne from '../../../../../components/account/campaigns/create/CreateCampaignStageOne/CreateCampaignStageOne';
import CreateCampaignStageTwo from '../../../../../components/account/campaigns/create/CreateCampaignStageTwo/CreateCampaignStageTwo';
import CreateCampaignStageThree from '../../../../../components/account/campaigns/create/CreateCampaignStageThree/CreateCampaignStageThree';
import CreateCampaignStageFour from '../../../../../components/account/campaigns/create/CreateCampaignStageFour/CreateCampaignStageFour';
import { FINAL_STAGE } from '../../../../../hooks/campaigns/useCreateCampaignStage/variables';
import { NextStage } from '../../../../../components/account/campaigns/create/Shared/Shared';
import { ErrorText, Info, Warning } from '../../../../../components/default/States/States';
import usePreventPageRefresh from '../../../../../hooks/campaigns/usePreventPageRefresh/usePreventPageRefresh';
import styles from './styles.module.scss';
import { UserContext } from '../../../../../contexts/UserContext/UserContext';
import { StyleEditorContext } from '../../../../../contexts/StyleEditorContext/StyleEditorContext';
import PopupTemplatePicker from '../../../../../components/account/campaigns/create/CreateCampaignStageTwo/PopupTemplatePicker/PopupTemplatePicker';
import DndBuilder from '../../../../../components/account/campaigns/create/CreateCampaignStageTwo/PopupBuilder/DndBuilder/DndBuilder';
import { DragOverEvent, DragEndEvent, closestCenter, DndContext } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import {
    DndElementType,
    dndElements,
} from '../../../../../components/account/campaigns/create/CreateCampaignStageTwo/PopupBuilder/DndBuilder/types';
import { DEFAULT_FONT_FAMILY } from '../../../../../utils/global-variables';
import generateUUIDWithFirstCharString from '../../../../../utils/HelperFunctions/generateUUIDWithFirstCharString';

type CreateCampaignFormInputs = any;

const NewCampaign = () => {
    const { user } = useContext(UserContext);
    const userPlan = user?.userPlan;
    const isFreePlan = userPlan === 'FREE';
    /*
        mutations:
        - createCampaign
        - createPopup
        - users must have to option of us storing emails, which would require mutation createSignUpData in the HTML

        stages:
        1. initial info:
        - campaign: name, type, storeInOurDatabase
        - popup: name
        2. appearance:
        - popup: htmlContent
        - add CSS field?
        - add JS field?
        - display preview button
        - display html content preview
        3. display:
        - popup: exitIntent, showAfterScrollPercentage, triggeredByClickingElements
        4. targeting:
        - campaign: pages, cookies, url params, devices
    */
    const methods = useForm<CreateCampaignFormInputs>({
        resolver: yupResolver(createCampaignSchema),
        mode: 'onTouched',
        defaultValues: {
            campaignName: '',
            campaignType: '',
            campaignStoreInOurDatabase: null,
            popupName: '',
            popupHtml: '',
            popupCss: '',
            popupJs: null,
            popupHowToShow: '',
            popupShowAfterScrollPercentage: null,
            popupTriggeredByClickingElements: null,
            campaignPages: {
                exclude: null,
                include: null,
                // exclude: [
                //     {
                //         type: 'contains',
                //         value: 'some_part_of_url',
                //     },
                //     {
                //         type: 'contains',
                //         value: 'another_url_part',
                //     },
                // ],
                // include: [
                // {
                //     type: 'equals',
                //     value: 'https://example.com/home',
                // },
                //     {
                //         type: 'includes',
                //         value: 'some_part_of_das_url',
                //     },
                // ],
            },
            campaignCookies: {
                exclude: [], // or null?
                include: [], // or null?
                // exclude: ['excluded_cookie_1', 'excluded_cookie_2'],
                // include: ['included_cookie_1', 'included_cookie_2'],
            },
            campaignUrlParams: {
                exclude: [], // or null?
                include: [], // or null?
                // exclude: ['excluded_param_1', 'excluded_param_2'],
                // include: ['included_param_1', 'included_param_2'],
            },
            campaignDevices: ['mobiles', 'desktops'],
        },
    });

    const {
        currentStage,
        currentStageComplete,
        proceed,
        showPrevStep,
        title,
        loadingSubmission,
        submissionError,
    } = useCreateCampaignStage(methods);

    usePreventPageRefresh();

    const {
        elements,
        setElements,
        bodyStyles,
        closeButtonStyles,
        setPreviewMode,
        activeTab,
        setTransformedHTML,
        codeContainerRef,
    } = useContext(StyleEditorContext);
    const popupJs = methods.watch('popupJs');
    const popupCss = methods.watch('popupCss');

    // comment in for debugging
    // console.log('popup elements and styles: ', {
    //     elements,
    //     bodyStyles,
    //     closeButtonStyles
    // })

    useEffect(() => {
        // sets preview mode to false if they click an element button, edit an element, etc
        setPreviewMode(false);
    }, [elements, bodyStyles, closeButtonStyles, activeTab, popupJs, popupCss, setPreviewMode]);

    const handleDrop = useCallback(
        (event: DragOverEvent) => {
            const elementType = event.active.id as DndElementType;
            const clickedNewElementIcon = dndElements.includes(elementType);

            if (!clickedNewElementIcon) return;

            // if (event.over?.id === 'droppable-area') {
            setElements((prev) => [
                ...prev,
                {
                    id: generateUUIDWithFirstCharString(),
                    type: elementType,
                    btn:
                        elementType === 'button'
                            ? {
                                  text: 'Change Button Text',
                                  hyperlink: '',
                                  onClickClose: false,
                              }
                            : undefined,
                    ...(elementType === 'button' && {
                        styles: {
                            element: {
                                regular: {
                                    display: 'inline-block',
                                    fontFamily: DEFAULT_FONT_FAMILY,
                                },
                            },
                        },
                    }),
                    ...(elementType === 'hr' && {
                        styles: {
                            element: {
                                regular: {
                                    height: '1px',
                                    backgroundColor: '#cccccc',
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                    display: 'block',
                                },
                            },
                        },
                    }),
                    ...(elementType === 'email' && {
                        styles: {
                            formElements: {
                                form: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    justifyContent: 'center',
                                },
                                emailInput: {
                                    fontFamily: DEFAULT_FONT_FAMILY,
                                },
                                emailInputLabel: {
                                    fontFamily: DEFAULT_FONT_FAMILY,
                                },
                                submitButton: {
                                    fontFamily: DEFAULT_FONT_FAMILY,
                                },
                            },
                        },
                    }),
                    ...(elementType === 'rich-text' && {
                        styles: {
                            element: {
                                regular: {
                                    display: 'block',
                                },
                            },
                        },
                        html: { inner: 'Enter text here' }, // adding default html here also somehow fixes the bug where, if creating a new rich text element and selecting it from another element populates the innerhtml with the other rich text element's content
                    }),
                },
            ]);
            // }
        },
        [setElements]
    );

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event;

            if (over && active.id !== over.id && elements.some((e) => e.id === active.id)) {
                setElements((items) => {
                    const oldIndex = items.findIndex((item) => item.id === active.id);
                    const newIndex = items.findIndex((item) => item.id === over.id);
                    return arrayMove(items, oldIndex, newIndex);
                });
            } else {
                handleDrop(event);
            }
        },
        [handleDrop, elements, setElements]
    );

    return (
        <>
            <h1 className={defaultStyles.centralisedText}>Create Campaign</h1>
            {currentStage === 3 && (
                <Warning
                    msg='We recommend using a computer or similar with a larger screen when creating popups, otherwise you may encounter display issues.'
                    marginBottom={true}
                    cls={styles.mobileOnly}
                />
            )}
            <FormProvider {...methods}>
                <div className={currentStage === 3 ? styles.whenStageTwo : ''}>
                    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                        <InfoAreaWrap cls={currentStage === 3 ? styles.infoArea : ''}>
                            <form>
                                <div className={defaultStyles.formInner}>
                                    <h2>{title}</h2>
                                    {currentStage === 1 && <CreateCampaignStageOne />}
                                    {currentStage === 2 && <PopupTemplatePicker onSkip={proceed} />}
                                    {currentStage === 3 && <CreateCampaignStageTwo />}
                                    {currentStage === 4 && <CreateCampaignStageThree />}
                                    {currentStage === FINAL_STAGE && <CreateCampaignStageFour />}
                                    <NextStage
                                        disabled={!currentStageComplete}
                                        onClick={() =>
                                            proceed(
                                                setTransformedHTML(
                                                    codeContainerRef.current,
                                                    methods.setValue
                                                )
                                            )
                                        }
                                        type='button'
                                        loading={loadingSubmission}
                                    />
                                    {currentStage > 1 && <Back onClick={showPrevStep} />}
                                    {submissionError && (
                                        <ErrorText msg={submissionError} marginTop={true} />
                                    )}
                                </div>
                            </form>
                        </InfoAreaWrap>
                        {currentStage === 3 && <DndBuilder />}
                    </DndContext>
                </div>
            </FormProvider>
            {currentStage === 3 && isFreePlan && (
                <Info msg="The bottom of every page will display a subtle 'Free website popups by Popup Hawk' message because you are on the free plan. You can remove this by upgrading to a paid plan." />
            )}
            <Back to='/user/campaigns' text='Back to Campaigns' />
        </>
    );
};

export default withPage(NewCampaign)({
    meta: createCampaignMeta,
    innerSpacing: true,
    lightBackground: true,
    clsPage: styles.page,
    bodyBackgroundColor: '#eaf5f0', // $green-tinted;
});
