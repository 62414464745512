import React, { useContext } from 'react';
import { StyleEditorContext } from '../../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import getValueFromPath from '../../../../../../../../../../utils/HelperFunctions/getValueFromPath';
import stylePanelStyles from '../../styles.module.scss';

interface TextAlignChangerProps {
    path: string[];
}

const TextAlignChanger = ({ path }: TextAlignChangerProps): JSX.Element => {
    const { selectedElement, handleStyleChange } = useContext(StyleEditorContext);

    return (
        <label className={stylePanelStyles['inner-label']} htmlFor='textAlign'>
            Text Align:
            <select
                id='textAlign'
                value={getValueFromPath(selectedElement?.styles, path)?.textAlign || 'left'}
                onChange={(e) =>
                    handleStyleChange({
                        style: 'textAlign',
                        path,
                        value: e.target.value,
                    })
                }
            >
                <option value='left'>Left</option>
                <option value='center'>Center</option>
                <option value='right'>Right</option>
                <option value='justify'>Justify</option>
            </select>
        </label>
    );
};

export default TextAlignChanger;
