import React, { useContext } from 'react';
import styles from './styles.module.scss';
import { StyleEditorContext } from '../../../../../../../contexts/StyleEditorContext/StyleEditorContext';

const BuilderTabs = () => {
    const { activeTab, setActiveTab } = useContext(StyleEditorContext);
    return (
        <ul className={styles.builderList}>
            <li
                className={activeTab === 'elements' ? styles.active : ''}
                onClick={() => setActiveTab('elements')}
            >
                Elements
            </li>
            <li
                className={activeTab === 'styles' ? styles.active : ''}
                onClick={() => setActiveTab('styles')}
            >
                Styles
            </li>
            <li
                className={activeTab === 'code' ? styles.active : ''}
                onClick={() => setActiveTab('code')}
            >
                Code
            </li>
        </ul>
    );
};

export default BuilderTabs;
