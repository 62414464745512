import React, { useCallback, useContext } from 'react';
import { StyleEditorContext } from '../../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import BackgroundColourChanger from '../../styling/BackgroundColourChanger/BackgroundColourChanger';
import BorderStyleChanger from '../../styling/BorderStyleChanger/BorderStyleChanger';
import FontFamilyChanger from '../../styling/FontFamilyChanger/FontFamilyChanger';
import FontSizeChanger from '../../styling/FontSizeChanger/FontSizeChanger';
import TextColourChanger from '../../styling/TextColourChanger/TextColourChanger';
import TextStyleChanger from '../../styling/TextStyleChanger/TextStyleChanger';
import PaddingChanger from '../../styling/PaddingChanger/PaddingChanger';
import MarginChanger from '../../styling/MarginChanger/MarginChanger';
import OpacityChanger from '../../styling/OpacityChanger/OpacityChanger';
import TextAlignChanger from '../../styling/TextAlignChanger/TextAlignChanger';
import AlignSelfChanger from '../../styling/AlignSelfChanger/AlignSelfChanger';
import stylePanelStyles from '../../styles.module.scss';

interface ButtonEditorProps {
    path?: string[];
    isFormButton?: boolean;
    title?: string;
    largeHeading?: boolean;
}

const ButtonEditor = ({
    path = ['element', 'regular'],
    isFormButton = false,
    title = 'Button Editor',
    largeHeading = true,
}: ButtonEditorProps): JSX.Element => {
    const { selectedElement, updateElement } = useContext(StyleEditorContext);

    const handleTextChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newText = e.target.value;
            if (!selectedElement) return;
            updateElement(selectedElement.id, { btn: { ...selectedElement.btn, text: newText } });
        },
        [selectedElement, updateElement]
    );

    const handleHyperlinkChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newHyperlink = e.target.value;
            if (!selectedElement) return;
            updateElement(selectedElement.id, {
                btn: { ...selectedElement.btn, hyperlink: newHyperlink },
            });
        },
        [selectedElement, updateElement]
    );

    const handleCloseButtonToggle = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const onClickClose = e.target.checked;
            if (!selectedElement) return;
            updateElement(selectedElement.id, {
                btn: { ...selectedElement.btn, onClickClose },
            });
        },
        [selectedElement, updateElement]
    );

    return (
        <div className={stylePanelStyles['editor-container']}>
            {largeHeading ? <h2>{title}</h2> : <h3>{title}</h3>}
            <BackgroundColourChanger path={path} />
            <AlignSelfChanger path={path} />
            <TextAlignChanger path={path} />
            <TextColourChanger path={path} />
            <TextStyleChanger path={path} />
            <FontSizeChanger path={path} />
            <FontFamilyChanger path={path} />
            <BorderStyleChanger path={path} />
            <PaddingChanger path={path} />
            <MarginChanger path={path} />
            <OpacityChanger path={path} />
            <label htmlFor='btnText' className={stylePanelStyles['inner-label']}>
                Button Text:
                <input
                    id='btnText'
                    type='text'
                    value={selectedElement?.btn?.text || ''}
                    onChange={handleTextChange}
                    placeholder='Enter button text'
                />
            </label>
            {!isFormButton && !selectedElement?.btn?.onClickClose && (
                <label htmlFor='btnHyperlink' className={stylePanelStyles['inner-label']}>
                    Button Hyperlink:{' '}
                    <input
                        id='btnHyperlink'
                        type='text'
                        value={selectedElement?.btn?.hyperlink || ''}
                        onChange={handleHyperlinkChange}
                        placeholder='Enter button hyperlink'
                    />
                </label>
            )}
            {!isFormButton && (
                <label htmlFor='btnClose' className={stylePanelStyles['inner-label']}>
                    Make this a Close Button:{' '}
                    <input
                        id='btnClose'
                        type='checkbox'
                        checked={selectedElement?.btn?.onClickClose || false}
                        onChange={handleCloseButtonToggle}
                    />
                </label>
            )}
        </div>
    );
};

export default ButtonEditor;
