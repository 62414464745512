import React, { useContext, useState } from 'react';
import { StyleEditorContext } from '../../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import getValueFromPath from '../../../../../../../../../../utils/HelperFunctions/getValueFromPath';
import stylePanelStyles from '../../styles.module.scss';
import styles from './styles.module.scss';

interface BackgroundColourChangerProps {
    path: string[];
}

const BackgroundColourChanger = ({ path }: BackgroundColourChangerProps): JSX.Element => {
    const { selectedElement, handleStyleChange } = useContext(StyleEditorContext);
    const [isNone, setIsNone] = useState(
        getValueFromPath(selectedElement?.styles, path)?.backgroundColor === 'none'
    );

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked ? 'none' : '#ffffff';
        setIsNone(e.target.checked);
        handleStyleChange({
            style: 'backgroundColor',
            path,
            value,
        });
    };

    return (
        <div className={styles.container}>
            <label className={stylePanelStyles['inner-label']} htmlFor='backgroundColor'>
                Background Colour:
                <input
                    className={stylePanelStyles['colour-picker']}
                    id='backgroundColor'
                    type='color'
                    value={
                        getValueFromPath(selectedElement?.styles, path)?.backgroundColor ||
                        '#ffffff'
                    }
                    onChange={(e) =>
                        handleStyleChange({
                            style: 'backgroundColor',
                            path,
                            value: e.target.value,
                        })
                    }
                    disabled={isNone}
                />
            </label>
            <label className={stylePanelStyles['inner-label']} htmlFor='backgroundNone'>
                None:
                <input
                    id='backgroundNone'
                    type='checkbox'
                    checked={isNone}
                    onChange={handleCheckboxChange}
                />
            </label>
        </div>
    );
};

export default BackgroundColourChanger;
