import React, { useCallback, useContext } from 'react';
import { StyleEditorContext } from '../../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import getValueFromPath from '../../../../../../../../../../utils/HelperFunctions/getValueFromPath';
import stylePanelStyles from '../../styles.module.scss';
import styles from './styles.module.scss';

interface TextStyleChangerProps {
    path: string[];
}

const TextStyleChanger = ({ path }: TextStyleChangerProps): JSX.Element => {
    const { selectedElement, handleStyleChange } = useContext(StyleEditorContext);

    const toggleStyle = useCallback(
        (styleKey: keyof React.CSSProperties, activeValue: string, inactiveValue: string) => {
            const currentValue = getValueFromPath(selectedElement?.styles, path)?.[styleKey];
            handleStyleChange({
                style: styleKey,
                path,
                value: currentValue === activeValue ? inactiveValue : activeValue,
            });
        },
        [handleStyleChange, selectedElement?.styles, path]
    );

    return (
        <div className={styles.container}>
            <h4>Text Styles:</h4>
            <label className={stylePanelStyles['inner-label']}>
                <input
                    type='checkbox'
                    checked={getValueFromPath(selectedElement?.styles, path)?.fontWeight === 'bold'}
                    onChange={() => toggleStyle('fontWeight', 'bold', 'normal')}
                />
                Bold
            </label>
            <label className={stylePanelStyles['inner-label']}>
                <input
                    type='checkbox'
                    checked={
                        (
                            getValueFromPath(selectedElement?.styles, path)
                                ?.textDecoration as string
                        )?.includes('underline') || false
                    }
                    onChange={() => toggleStyle('textDecoration', 'underline', 'none')}
                />
                Underline
            </label>
            <label className={stylePanelStyles['inner-label']}>
                <input
                    type='checkbox'
                    checked={
                        getValueFromPath(selectedElement?.styles, path)?.fontStyle === 'italic'
                    }
                    onChange={() => toggleStyle('fontStyle', 'italic', 'normal')}
                />
                Italic
            </label>
        </div>
    );
};

export default TextStyleChanger;
