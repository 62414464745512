import { CSSProperties } from 'react';

export type DndElementType = 'hr' | 'button' | 'email' | 'rich-text';

export const dndElements = ['hr', 'button', 'email', 'rich-text'];

export type DndElement = {
    id: string;
    type: string;
    html?: {
        outer?: string;
        inner?: string;
        styles?: CSSProperties;
    };
    styles?: {
        element?: {
            // element is denoted by an id
            regular?: CSSProperties;
            hover?: CSSProperties;
        };
        formElements?: {
            // form is denoted by an id
            form?: CSSProperties;
            emailInput?: CSSProperties;
            emailInputLabel?: CSSProperties;
            submitButton?: CSSProperties;
        };
    };
    btn?: {
        text?: string;
        hyperlink?: string | null;
        onClickClose?: boolean;
    };
    selected?: boolean;
};

export type updateElementFn = (id: string, newProperties: Record<string, any>) => void;
