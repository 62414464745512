import React, {
    useRef,
    CSSProperties,
    useEffect,
    useContext,
    useCallback,
    useMemo,
    useState,
} from 'react';
import { StyleEditorContext } from '../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import { DEFAULT_FONT_FAMILY } from '../../../../../../../../../utils/global-variables';
import MarginChanger from '../../../StylePanel/components/styling/MarginChanger/MarginChanger';
import PaddingChanger from '../../../StylePanel/components/styling/PaddingChanger/PaddingChanger';
import BackgroundColourChanger from '../../../StylePanel/components/styling/BackgroundColourChanger/BackgroundColourChanger';
import BorderStyleChanger from '../../../StylePanel/components/styling/BorderStyleChanger/BorderStyleChanger';
import stylePanelStyles from '../../../StylePanel/components/styles.module.scss';
import rteStyles from './styles.module.scss';
import {
    faAlignLeft,
    faBold,
    faItalic,
    faRemoveFormat,
    faStrikethrough,
    faUnderline,
    faFont,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PATH = ['element', 'regular'];

// Function to save the cursor position
const saveCursorPosition = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
        return selection.getRangeAt(0);
    }
    return null;
};

// Function to restore the cursor position
const restoreCursorPosition = (range: Range | null) => {
    if (range) {
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }
};

const RichTextEditor = () => {
    const editorRef = useRef<HTMLDivElement>(null);
    const previousSelectedElementId = useRef<string | null>(null);
    const { updateElement, selectedElement } = useContext(StyleEditorContext);
    const styles = useMemo(
        () =>
            selectedElement?.html?.styles || {
                fontSize: 16,
                color: '#000000',
                textAlign: 'left' as CSSProperties['textAlign'],
                fontFamily: DEFAULT_FONT_FAMILY,
                opacity: 1,
            },
        [selectedElement]
    );
    const [showTextAlignOptions, setShowTextAlignOptions] = useState<boolean>(false);
    const [showFontFamilyOptions, setShowFontFamilyOptions] = useState<boolean>(false);

    // Define the updateHtml function and store it in a ref
    const updateHtml = useCallback(() => {
        const cursorPosition = saveCursorPosition(); // Save cursor position before updating HTML
        if (editorRef.current && selectedElement) {
            // Create a new div element
            const div = document.createElement('div');
            div.id = selectedElement.id;
            div.className = 'ph-text';
            // Set the styles of the div with default values
            div.style.fontFamily = styles.fontFamily ?? DEFAULT_FONT_FAMILY;
            div.style.fontSize = `${styles.fontSize ?? 16}px`;
            div.style.color = styles.color ?? '#000000';
            div.style.textAlign = styles.textAlign ?? 'left';
            div.style.opacity = `${styles.opacity ?? 1}`;
            // Set the innerHTML of the div
            div.innerHTML = editorRef.current.innerHTML;

            // Update the element with the new outer HTML and styles
            updateElement(selectedElement.id, {
                html: {
                    outer: div.outerHTML,
                    inner: editorRef.current.innerHTML,
                    styles,
                },
            });
        }
        restoreCursorPosition(cursorPosition); // Restore cursor position after updating HTML
    }, [selectedElement, updateElement, styles]);

    const applyFormat = useCallback(
        (tag: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            const selection = window.getSelection();
            if (selection && selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                if (
                    editorRef.current &&
                    editorRef.current.contains(range.commonAncestorContainer)
                ) {
                    const element = document.createElement(tag);
                    element.appendChild(range.extractContents());
                    range.insertNode(element);
                    selection.removeAllRanges();
                }
            }
            updateHtml();
        },
        [updateHtml]
    );

    useEffect(() => {
        if (editorRef.current) {
            const handlePaste = (e: ClipboardEvent) => {
                e.preventDefault();
                const text = e.clipboardData?.getData('text/plain');
                if (text) {
                    const selection = window.getSelection();
                    if (selection && selection.rangeCount > 0) {
                        const range = selection.getRangeAt(0);
                        range.deleteContents();
                        range.insertNode(document.createTextNode(text));
                    }
                }
                updateHtml();
            };

            const handleKeyDown = (e: KeyboardEvent) => {
                if ((e.metaKey || e.ctrlKey) && (e.key === 'b' || e.key === 'i' || e.key === 'u')) {
                    e.preventDefault();
                    if (e.key === 'b')
                        applyFormat('b')(e as unknown as React.MouseEvent<HTMLButtonElement>);
                    if (e.key === 'i')
                        applyFormat('i')(e as unknown as React.MouseEvent<HTMLButtonElement>);
                    if (e.key === 'u')
                        applyFormat('u')(e as unknown as React.MouseEvent<HTMLButtonElement>);
                }
            };

            const editor = editorRef.current;
            editor.addEventListener('paste', handlePaste);
            editor.addEventListener('keydown', handleKeyDown);
            editor.addEventListener('input', updateHtml);

            return () => {
                editor.removeEventListener('paste', handlePaste);
                editor.removeEventListener('keydown', handleKeyDown);
                editor.removeEventListener('input', updateHtml);
            };
        }
    }, [selectedElement, updateElement, applyFormat, updateHtml]);

    const handleStyleChange = useCallback(
        (key: string, value: string | number) => {
            if (selectedElement) {
                const updatedStyles = {
                    ...styles,
                    [key]: value,
                };
                updateElement(selectedElement.id, {
                    html: {
                        ...selectedElement.html,
                        styles: updatedStyles,
                    },
                });
                if (editorRef.current) {
                    (editorRef.current.style as any)[key] = value ?? ''; // Provide default value
                }
            }
        },
        [selectedElement, updateElement, styles]
    );

    const clearFormatting = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            if (editorRef.current) {
                ['u', 'b', 'i', 'strike'].forEach((tag) => {
                    const elements = editorRef.current!.querySelectorAll(tag);
                    elements.forEach((element) => {
                        const parent = element.parentNode;
                        while (element.firstChild) {
                            parent?.insertBefore(element.firstChild, element);
                        }
                        parent?.removeChild(element);
                    });
                });
            }
            updateHtml();
        },
        [updateHtml]
    );

    useEffect(() => {
        if (
            editorRef.current &&
            selectedElement?.html?.inner &&
            previousSelectedElementId.current !== selectedElement.id
        ) {
            editorRef.current.innerHTML = selectedElement.html.inner;
            previousSelectedElementId.current = selectedElement.id;
        }
    }, [selectedElement]);

    const handleTextAlignChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        handleStyleChange('textAlign', e.target.value);
        setShowTextAlignOptions(false);
    };

    const handleFontFamilyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        handleStyleChange('fontFamily', e.target.value);
        setShowFontFamilyOptions(false);
    };

    return (
        <div className={stylePanelStyles['editor-container']}>
            <h2>Text Editor</h2>
            <MarginChanger path={PATH} />
            <PaddingChanger path={PATH} />
            <BackgroundColourChanger path={PATH} />
            <BorderStyleChanger path={PATH} />
            <label htmlFor='opacity' className={stylePanelStyles['inner-label']}>
                Opacity:
                <input
                    id='opacity'
                    type='range'
                    min='0'
                    max='1'
                    step='0.01'
                    value={styles.opacity}
                    onChange={(e) => handleStyleChange('opacity', parseFloat(e.target.value))}
                    className={stylePanelStyles['range']}
                />
            </label>
            <div className={rteStyles['outer-container']}>
                <div className={rteStyles['sub-container']}>
                    <button onClick={clearFormatting} className={rteStyles['pipe']}>
                        <FontAwesomeIcon icon={faRemoveFormat} />
                    </button>
                    <button onClick={applyFormat('b')}>
                        <FontAwesomeIcon icon={faBold} />
                    </button>
                    <button onClick={applyFormat('i')}>
                        <FontAwesomeIcon icon={faItalic} />
                    </button>
                    <button onClick={applyFormat('u')}>
                        <FontAwesomeIcon icon={faUnderline} />
                    </button>
                    <button onClick={applyFormat('strike')} className={rteStyles['pipe']}>
                        <FontAwesomeIcon icon={faStrikethrough} />
                    </button>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            setShowTextAlignOptions(true);
                        }}
                        className={`${rteStyles['text-aligner']} ${rteStyles['pipe']}`}
                    >
                        <FontAwesomeIcon icon={faAlignLeft} />
                        {showTextAlignOptions && (
                            <select
                                value={styles.textAlign}
                                onChange={handleTextAlignChange}
                                onBlur={() => setShowTextAlignOptions(false)}
                            >
                                <option value='left'>Left</option>
                                <option value='center'>Center</option>
                                <option value='right'>Right</option>
                                <option value='justify'>Justify</option>
                            </select>
                        )}
                    </button>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            setShowFontFamilyOptions(true);
                        }}
                        className={`${rteStyles['font-family-picker']} ${rteStyles['pipe']}`}
                    >
                        <FontAwesomeIcon icon={faFont} />
                        {showFontFamilyOptions && (
                            <select
                                value={styles.fontFamily}
                                onChange={handleFontFamilyChange}
                                onBlur={() => setShowFontFamilyOptions(false)}
                            >
                                <option value='Arial'>Arial</option>
                                <option value='Verdana'>Verdana</option>
                                <option value='Times'>Times New Roman</option>
                                <option value='Georgia'>Georgia</option>
                                <option value='Courier'>Courier</option>
                            </select>
                        )}
                    </button>
                    <input
                        className={stylePanelStyles['colour-picker']}
                        type='color'
                        value={styles.color}
                        onChange={(e) => handleStyleChange('color', e.target.value)}
                    />
                    <input
                        className={rteStyles['font-size']}
                        type='number'
                        value={styles.fontSize}
                        onChange={(e) =>
                            handleStyleChange('fontSize', parseInt(e.target.value, 10))
                        }
                    />
                </div>
                <div
                    ref={editorRef}
                    contentEditable
                    className={`ph-text ${rteStyles.editor}`}
                    style={selectedElement?.html?.styles}
                    suppressContentEditableWarning={true}
                />
            </div>
        </div>
    );
};

export default RichTextEditor;
