import React, { useContext } from 'react';
import { StyleEditorContext } from '../../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import getValueFromPath from '../../../../../../../../../../utils/HelperFunctions/getValueFromPath';
import stylePanelStyles from '../../styles.module.scss';

interface AlignSelfChangerProps {
    path: string[];
}

const AlignSelfChanger = ({ path }: AlignSelfChangerProps): JSX.Element => {
    const { selectedElement, handleStyleChange } = useContext(StyleEditorContext);

    return (
        <label className={stylePanelStyles['inner-label']} htmlFor='alignSelf'>
            Align Self:
            <select
                id='alignSelf'
                value={getValueFromPath(selectedElement?.styles, path)?.alignSelf || 'auto'}
                onChange={(e) =>
                    handleStyleChange({
                        style: 'alignSelf',
                        path,
                        value: e.target.value,
                    })
                }
            >
                <option value='flex-start'>Left</option>
                <option value='center'>Center</option>
                <option value='flex-end'>Right</option>
                <option value='stretch'>Stretch</option>
            </select>
        </label>
    );
};

export default AlignSelfChanger;
