import React, { useCallback, useContext } from 'react';
import { StyleEditorContext } from '../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import stylePanelStyles from '../styles.module.scss';

const BodyEditor = () => {
    // N.B.: perhaps the styling components (e.g. <BackgroundColourChanger />) could be reused somehow with this instead

    const { bodyStyles, setBodyStyles } = useContext(StyleEditorContext);

    const updateStyle = useCallback(
        (key: keyof React.CSSProperties, value: string) => {
            setBodyStyles((prevStyles) => ({
                ...prevStyles,
                [key]: value,
            }));
        },
        [setBodyStyles]
    );

    return (
        <div className={stylePanelStyles['editor-container']}>
            <h2>Body Editor</h2>
            <label className={stylePanelStyles['inner-label']}>
                Background Colour:
                <input
                    className={stylePanelStyles['colour-picker']}
                    type='color'
                    value={bodyStyles.backgroundColor || '#ffffff'}
                    onChange={(e) => updateStyle('backgroundColor', e.target.value)}
                />
            </label>
            <label className={stylePanelStyles['inner-label']}>
                Border Radius:
                <input
                    type='range'
                    min='0'
                    max='100'
                    value={parseInt(bodyStyles.borderRadius as string, 10) || 0}
                    onChange={(e) => updateStyle('borderRadius', `${e.target.value}px`)}
                    className={stylePanelStyles['range']}
                />
            </label>
            <label className={stylePanelStyles['inner-label']}>
                Border Colour:
                <input
                    className={stylePanelStyles['colour-picker']}
                    type='color'
                    value={bodyStyles.borderColor || '#000000'}
                    onChange={(e) => updateStyle('borderColor', e.target.value)}
                />
            </label>
            <label className={stylePanelStyles['inner-label']}>
                Border Width:
                <input
                    type='range'
                    min='0'
                    max='30'
                    value={parseInt(bodyStyles.borderWidth as string, 10) || 0}
                    onChange={(e) => updateStyle('borderWidth', `${e.target.value}px`)}
                    className={stylePanelStyles['range']}
                />
            </label>
            <label className={stylePanelStyles['inner-label']}>
                Border Style:
                <select
                    value={bodyStyles.borderStyle || 'none'}
                    onChange={(e) => updateStyle('borderStyle', e.target.value)}
                >
                    <option value='none'>None</option>
                    <option value='solid'>Solid</option>
                    <option value='dashed'>Dashed</option>
                    <option value='dotted'>Dotted</option>
                    <option value='double'>Double</option>
                    <option value='groove'>Groove</option>
                    <option value='ridge'>Ridge</option>
                    <option value='inset'>Inset</option>
                    <option value='outset'>Outset</option>
                </select>
            </label>
            <label className={stylePanelStyles['inner-label']}>
                Padding:
                <input
                    type='range'
                    min='0'
                    max='100'
                    value={parseInt(bodyStyles.padding as string, 10) || 0}
                    onChange={(e) => updateStyle('padding', `${e.target.value}px`)}
                    className={stylePanelStyles['range']}
                />
            </label>
            <label className={stylePanelStyles['inner-label']}>
                Width:
                <input
                    type='range'
                    min='100'
                    max='2000'
                    value={parseInt(bodyStyles.width as string, 10) || 1000}
                    onChange={(e) => updateStyle('width', `${e.target.value}px`)}
                    className={stylePanelStyles['range']}
                />
            </label>
        </div>
    );
};

export default BodyEditor;
