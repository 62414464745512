import React, { useCallback, useContext } from 'react';
import { StyleEditorContext } from '../../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import getValueFromPath from '../../../../../../../../../../utils/HelperFunctions/getValueFromPath';
import styles from './styles.module.scss';

interface PaddingChangerProps {
    path: string[];
}

const PaddingChanger = ({ path }: PaddingChangerProps): JSX.Element => {
    const { selectedElement, handleStyleChange } = useContext(StyleEditorContext);

    const handlePaddingChange = useCallback(
        (side: 'top' | 'bottom' | 'left' | 'right') => (e: React.ChangeEvent<HTMLInputElement>) => {
            const padding = e.target.value + 'px';
            handleStyleChange({
                style: `padding${
                    side.charAt(0).toUpperCase() + side.slice(1)
                }` as keyof React.CSSProperties,
                path,
                value: padding,
            });
        },
        [handleStyleChange, path]
    );

    return (
        <div className={styles.container}>
            <p>Padding:</p>
            <input
                id='paddingTop'
                type='number'
                min='0'
                max='100'
                value={parseInt(
                    getValueFromPath(selectedElement?.styles, path)?.paddingTop || '0',
                    10
                )}
                onChange={handlePaddingChange('top')}
            />
            <input
                id='paddingBottom'
                type='number'
                min='0'
                max='100'
                value={parseInt(
                    getValueFromPath(selectedElement?.styles, path)?.paddingBottom || '0',
                    10
                )}
                onChange={handlePaddingChange('bottom')}
            />
            <input
                id='paddingLeft'
                type='number'
                min='0'
                max='100'
                value={parseInt(
                    getValueFromPath(selectedElement?.styles, path)?.paddingLeft || '0',
                    10
                )}
                onChange={handlePaddingChange('left')}
            />
            <input
                id='paddingRight'
                type='number'
                min='0'
                max='100'
                value={parseInt(
                    getValueFromPath(selectedElement?.styles, path)?.paddingRight || '0',
                    10
                )}
                onChange={handlePaddingChange('right')}
            />
        </div>
    );
};

export default PaddingChanger;
