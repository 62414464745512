import React, { useContext } from 'react';
import { StyleEditorContext } from '../../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import getValueFromPath from '../../../../../../../../../../utils/HelperFunctions/getValueFromPath';
import stylePanelStyles from '../../styles.module.scss';

interface JustifyContentChangerProps {
    path: string[];
}

const JustifyContentChanger = ({ path }: JustifyContentChangerProps): JSX.Element => {
    const { selectedElement, handleStyleChange } = useContext(StyleEditorContext);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        handleStyleChange({
            style: 'justifyContent',
            path,
            value: e.target.value,
        });
    };

    return (
        <label className={stylePanelStyles['inner-label']} htmlFor='justifyContent'>
            Justify Content:
            <select
                id='justifyContent'
                value={
                    getValueFromPath(selectedElement?.styles, path)?.justifyContent || 'flex-start'
                }
                onChange={handleChange}
            >
                <option value='flex-start'>Start</option>
                <option value='flex-end'>End</option>
                <option value='center'>Center</option>
                <option value='space-between'>Between</option>
                <option value='space-around'>Around</option>
                <option value='space-evenly'>Even</option>
            </select>
        </label>
    );
};

export default JustifyContentChanger;
