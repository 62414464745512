import React, { useContext } from 'react';
import { StyleEditorContext } from '../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import ButtonEditor from './ButtonEditor/ButtonEditor';
import FormEditor from './FormEditor/FormEditor';
import LineEditor from './LineEditor/LineEditor';
import RichTextEditor from '../../../DndBuilder/SortableItem/RichTextEditor/RichTextEditor';

const ElementEditor = () => {
    const { selectedElement } = useContext(StyleEditorContext);
    const elementType = selectedElement?.type;

    if (!selectedElement) return null;

    return (
        <div>
            {/* <StyleEditorSection title='Margin' styleType='margin' />
            <StyleEditorSection title='Padding' styleType='padding' /> */}
            {elementType === 'hr' && <LineEditor />}
            {elementType === 'button' && <ButtonEditor />}
            {elementType === 'email' && <FormEditor />}
            {elementType === 'rich-text' && <RichTextEditor />}
        </div>
    );
};

export default ElementEditor;
