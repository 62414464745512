import React from 'react';
import TextStyleChanger from '../../../styling/TextStyleChanger/TextStyleChanger';
import TextColourChanger from '../../../styling/TextColourChanger/TextColourChanger';
import BackgroundColourChanger from '../../../styling/BackgroundColourChanger/BackgroundColourChanger';
import FontFamilyChanger from '../../../styling/FontFamilyChanger/FontFamilyChanger';
import FontSizeChanger from '../../../styling/FontSizeChanger/FontSizeChanger';
import MarginChanger from '../../../styling/MarginChanger/MarginChanger';
import PaddingChanger from '../../../styling/PaddingChanger/PaddingChanger';
import DisplayToggle from '../../../styling/DisplayToggle/DisplayToggle';
import TextAlignChanger from '../../../styling/TextAlignChanger/TextAlignChanger';
import stylePanelStyles from '../../../styles.module.scss';

const PATH = ['formElements', 'emailInputLabel'];

const EmailLabelEditor = () => {
    return (
        <div className={stylePanelStyles['editor-container']}>
            <h3>Label</h3>
            <DisplayToggle path={PATH} />
            <TextAlignChanger path={PATH} />
            <TextStyleChanger path={PATH} />
            <TextColourChanger path={PATH} />
            <FontSizeChanger path={PATH} />
            <BackgroundColourChanger path={PATH} />
            <FontFamilyChanger path={PATH} />
            <PaddingChanger path={PATH} />
            <MarginChanger path={PATH} />
        </div>
    );
};

export default EmailLabelEditor;
