import React, { useContext } from 'react';
import {
    faArrowDownUpAcrossLine,
    faCircleDot,
    faEnvelope,
    faFont,
} from '@fortawesome/free-solid-svg-icons';
import styles from './styles.module.scss';
import DraggableItem from '../DndBuilder/DraggableItem/DraggableItem';
import { StyleEditorContext } from '../../../../../../../contexts/StyleEditorContext/StyleEditorContext';

const ElementPanel = () => {
    const { elements } = useContext(StyleEditorContext);
    const emailElementAdded = elements.some((element) => element.type === 'email');

    return (
        <div className={styles.container}>
            <p>
                Click on an icon below to insert an element. Drag and drop to rearrange the
                position.
            </p>
            <ul>
                <DraggableItem id='hr' icon={faArrowDownUpAcrossLine} name='Line' />
                <DraggableItem id='rich-text' icon={faFont} name='Text' />
                <DraggableItem id='button' icon={faCircleDot} name='Button' />
                <DraggableItem
                    id='email'
                    icon={faEnvelope}
                    name='Email Form'
                    disabled={emailElementAdded}
                />
            </ul>
        </div>
    );
};

export default ElementPanel;
