import React from 'react';
import BackgroundColourChanger from '../../../styling/BackgroundColourChanger/BackgroundColourChanger';
import FlexDirectionChanger from '../../../styling/FlexDirectionChanger/FlexDirectionChanger';
import JustifyContentChanger from '../../../styling/JustifyContentChanger/JustifyContentChanger';
import AlignItemsChanger from '../../../styling/AlignItemsChanger/AlignItemsChanger';
import MarginChanger from '../../../styling/MarginChanger/MarginChanger';
import PaddingChanger from '../../../styling/PaddingChanger/PaddingChanger';
import OpacityChanger from '../../../styling/OpacityChanger/OpacityChanger';
import stylePanelStyles from '../../../styles.module.scss';

const PATH = ['formElements', 'form'];

const FormElementEditor = () => {
    return (
        <div className={stylePanelStyles['editor-container']}>
            <h3>Container</h3>
            <FlexDirectionChanger path={PATH} />
            <JustifyContentChanger path={PATH} />
            <AlignItemsChanger path={PATH} />
            <BackgroundColourChanger path={PATH} />
            <PaddingChanger path={PATH} />
            <MarginChanger path={PATH} />
            <OpacityChanger path={PATH} />
        </div>
    );
};

export default FormElementEditor;
