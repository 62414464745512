import React, { useContext } from 'react';
import { StyleEditorContext } from '../../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import getValueFromPath from '../../../../../../../../../../utils/HelperFunctions/getValueFromPath';
import stylePanelStyles from '../../styles.module.scss';

interface TextColourChangerProps {
    path: string[];
}

const TextColourChanger = ({ path }: TextColourChangerProps): JSX.Element => {
    const { selectedElement, handleStyleChange } = useContext(StyleEditorContext);

    return (
        <label className={stylePanelStyles['inner-label']} htmlFor='textColor'>
            Text Colour:
            <input
                id='textColor'
                className={stylePanelStyles['colour-picker']}
                type='color'
                value={getValueFromPath(selectedElement?.styles, path)?.color || '#000000'}
                onChange={(e) =>
                    handleStyleChange({
                        style: 'color',
                        path,
                        value: e.target.value,
                    })
                }
            />
        </label>
    );
};

export default TextColourChanger;
