import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './styles.module.scss';

interface ShadowWrapperProps {
    children: React.ReactNode;
}

const ShadowWrapper: React.FC<ShadowWrapperProps> = ({ children }) => {
    const shadowRootRef = useRef<HTMLDivElement | null>(null); // Reference to the div that will contain the shadow DOM
    const [shadowRoot, setShadowRoot] = useState<ShadowRoot | null>(null); // State to store the shadow root

    useEffect(() => {
        // Check if the shadowRootRef is set and shadowRoot is not already created
        if (shadowRootRef.current && !shadowRoot) {
            // Check if the shadow DOM is not already attached to the div
            if (!shadowRootRef.current.shadowRoot) {
                // Attach shadow DOM to the div
                const shadow = shadowRootRef.current.attachShadow({ mode: 'open' });
                setShadowRoot(shadow);
            } else {
                // If shadow root already exists, set it to state
                setShadowRoot(shadowRootRef.current.shadowRoot);
            }
        }
    }, [shadowRoot]);

    return (
        // Render children inside the shadow DOM
        <div ref={shadowRootRef} className={styles.shadowWrapper}>
            {shadowRoot && ReactDOM.createPortal(children, shadowRoot)}
        </div>
    );
};

export default ShadowWrapper;
