import React from 'react';
import BackgroundColourChanger from '../../styling/BackgroundColourChanger/BackgroundColourChanger';
import HeightChanger from '../../styling/HeightChanger/HeightChanger';
import MarginChanger from '../../styling/MarginChanger/MarginChanger';
import OpacityChanger from '../../styling/OpacityChanger/OpacityChanger';
import stylePanelStyles from '../../styles.module.scss';

const PATH = ['element', 'regular'];

const LineEditor = () => {
    return (
        <div className={stylePanelStyles['editor-container']}>
            <h2>Line Editor</h2>
            <BackgroundColourChanger path={PATH} />
            <HeightChanger path={PATH} />
            <MarginChanger path={PATH} />
            <OpacityChanger path={PATH} />
        </div>
    );
};

export default LineEditor;
