import React, { useContext } from 'react';
import BuilderTabs from './PopupBuilder/BuilderTabs/BuilderTabs';
import CodePanel from './PopupBuilder/CodePanel/CodePanel';
import StylePanel from './PopupBuilder/StylePanel/StylePanel';
import ElementPanel from './PopupBuilder/ElementPanel/ElementPanel';
import { StyleEditorContext } from '../../../../../contexts/StyleEditorContext/StyleEditorContext';

const CreateCampaignStageTwo = () => {
    const { activeTab, previewMode } = useContext(StyleEditorContext);

    if (previewMode) return null;

    return (
        <>
            <BuilderTabs />
            {activeTab === 'elements' && <ElementPanel />}
            {activeTab === 'styles' && <StylePanel />}
            {activeTab === 'code' && <CodePanel />}
        </>
    );
};

export default CreateCampaignStageTwo;
