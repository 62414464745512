/**
 * Function replacing <div data-temporary-form-replica='true'> with form tags
 * @param html - HTML string
 * @returns HTML string with divs replaced with forms
 */

const replaceReplicaDivsWithForms = (html: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const divs = doc.querySelectorAll('div[data-temporary-form-replica="true"]');
    divs.forEach((div) => {
        const form = document.createElement('form');
        if (div.id) {
            form.id = div.id;
        }
        while (div.firstChild) {
            form.appendChild(div.firstChild);
        }
        div.replaceWith(form);
    });

    return doc.body.innerHTML;
};

export default replaceReplicaDivsWithForms;
