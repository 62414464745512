import React, { useCallback, useContext } from 'react';
import { StyleEditorContext } from '../../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import getValueFromPath from '../../../../../../../../../../utils/HelperFunctions/getValueFromPath';
import stylePanelStyles from '../../styles.module.scss';

interface DisplayToggleProps {
    path: string[];
}

const DisplayToggle = ({ path }: DisplayToggleProps): JSX.Element => {
    const { selectedElement, handleStyleChange } = useContext(StyleEditorContext);

    const handleDisplayToggle = useCallback(() => {
        const currentDisplay = getValueFromPath(selectedElement?.styles, path)?.display;
        handleStyleChange({
            style: 'display',
            path,
            value: currentDisplay === 'none' ? 'block' : 'none',
        });
    }, [handleStyleChange, path, selectedElement?.styles]);

    return (
        <label className={stylePanelStyles['inner-label']}>
            Hide
            <input
                type='checkbox'
                checked={getValueFromPath(selectedElement?.styles, path)?.display === 'none'}
                onChange={handleDisplayToggle}
            />
        </label>
    );
};

export default DisplayToggle;
