import React, { useCallback, useContext } from 'react';
import { StyleEditorContext } from '../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import stylePanelStyles from '../styles.module.scss';
import styles from './styles.module.scss';

const CloseButtonEditor = () => {
    const { closeButtonStyles, setCloseButtonStyles } = useContext(StyleEditorContext);

    const updateStyle = useCallback(
        (key: keyof React.CSSProperties, value: string | undefined) => {
            setCloseButtonStyles((prevStyles) => ({
                ...prevStyles,
                [key]: value,
            }));
        },
        [setCloseButtonStyles]
    );

    const toggleUnsetStyle = useCallback(
        (key: keyof React.CSSProperties) => {
            setCloseButtonStyles((prevStyles) => ({
                ...prevStyles,
                [key]: prevStyles[key] === 'unset' ? '0px' : 'unset',
            }));
        },
        [setCloseButtonStyles]
    );

    return (
        <div className={stylePanelStyles['editor-container']}>
            <h2>Close Button</h2>
            <label className={stylePanelStyles['inner-label']}>
                Colour:
                <input
                    type='color'
                    className={stylePanelStyles['colour-picker']}
                    value={closeButtonStyles.color || '#ff0000'}
                    onChange={(e) => updateStyle('color', e.target.value)}
                />
            </label>

            <label htmlFor='fontSize' className={stylePanelStyles['inner-label']}>
                Size (px):{' '}
                <input
                    id='fontSize'
                    type='number'
                    min='10'
                    max='100'
                    value={parseInt(closeButtonStyles.fontSize as string, 10) || 16}
                    onChange={(e) => updateStyle('fontSize', `${e.target.value}px`)}
                />
            </label>

            <div className={styles['position-container']}>
                <p>
                    Position:
                    <br />
                    (px)
                </p>
                <div className={styles['quad-boxes-container']}>
                    <label htmlFor='top' className={stylePanelStyles['inner-label']}>
                        <input
                            id='top'
                            type='number'
                            value={parseInt(closeButtonStyles.top as string, 10) || 0}
                            onChange={(e) => updateStyle('top', `${e.target.value}px`)}
                        />
                        <span>T</span>
                    </label>
                    <label htmlFor='bottom' className={stylePanelStyles['inner-label']}>
                        <input
                            id='bottom'
                            type='number'
                            value={parseInt(closeButtonStyles.bottom as string, 10) || 0}
                            onChange={(e) => updateStyle('bottom', `${e.target.value}px`)}
                        />
                        <span>B</span>
                    </label>
                    <label htmlFor='left' className={stylePanelStyles['inner-label']}>
                        <input
                            id='left'
                            type='number'
                            value={parseInt(closeButtonStyles.left as string, 10) || 0}
                            onChange={(e) => updateStyle('left', `${e.target.value}px`)}
                        />
                        <span>L</span>
                    </label>
                    <label htmlFor='right' className={stylePanelStyles['inner-label']}>
                        <input
                            id='right'
                            type='number'
                            value={parseInt(closeButtonStyles.right as string, 10) || 0}
                            onChange={(e) => updateStyle('right', `${e.target.value}px`)}
                        />
                        <span>R</span>
                    </label>
                </div>
            </div>

            <div className={styles['unset-container']}>
                <p>Unset:</p>
                <div className={styles['container']}>
                    <label className={stylePanelStyles['inner-label']}>
                        <input
                            type='checkbox'
                            checked={closeButtonStyles.top === 'unset'}
                            onChange={() => toggleUnsetStyle('top')}
                        />
                        <span>T</span>
                    </label>

                    <label className={stylePanelStyles['inner-label']}>
                        <input
                            type='checkbox'
                            checked={closeButtonStyles.bottom === 'unset'}
                            onChange={() => toggleUnsetStyle('bottom')}
                        />
                        <span>B</span>
                    </label>

                    <label className={stylePanelStyles['inner-label']}>
                        <input
                            type='checkbox'
                            checked={closeButtonStyles.left === 'unset'}
                            onChange={() => toggleUnsetStyle('left')}
                        />
                        <span>L</span>
                    </label>

                    <label className={stylePanelStyles['inner-label']}>
                        <input
                            type='checkbox'
                            checked={closeButtonStyles.right === 'unset'}
                            onChange={() => toggleUnsetStyle('right')}
                        />
                        <span>R</span>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default CloseButtonEditor;
