import React, { useCallback, useContext } from 'react';
import { StyleEditorContext } from '../../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import getValueFromPath from '../../../../../../../../../../utils/HelperFunctions/getValueFromPath';
import stylePanelStyles from '../../styles.module.scss';

interface FontSizeChangerProps {
    path: string[];
}

const FontSizeChanger = ({ path }: FontSizeChangerProps): JSX.Element => {
    const { selectedElement, handleStyleChange } = useContext(StyleEditorContext);

    const handleFontSizeChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const fontSize = e.target.value + 'px';
            handleStyleChange({
                style: 'fontSize',
                path,
                value: fontSize,
            });
        },
        [handleStyleChange, path]
    );

    return (
        <label className={stylePanelStyles['inner-label']} htmlFor='fontSize'>
            Font Size (px):
            <input
                id='fontSize'
                type='number'
                min='10'
                max='100'
                value={parseInt(
                    getValueFromPath(selectedElement?.styles, path)?.fontSize || '16',
                    10
                )}
                onChange={handleFontSizeChange}
            />
        </label>
    );
};

export default FontSizeChanger;
