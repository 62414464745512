import React, { useCallback, useContext } from 'react';
import { StyleEditorContext } from '../../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import getValueFromPath from '../../../../../../../../../../utils/HelperFunctions/getValueFromPath';
import styles from '../PaddingChanger/styles.module.scss';

interface MarginChangerProps {
    path: string[];
}

const MarginChanger = ({ path }: MarginChangerProps): JSX.Element => {
    const { selectedElement, handleStyleChange } = useContext(StyleEditorContext);

    const handleMarginChange = useCallback(
        (side: 'top' | 'bottom' | 'left' | 'right') => (e: React.ChangeEvent<HTMLInputElement>) => {
            const margin = e.target.value + 'px';
            handleStyleChange({
                style: `margin${
                    side.charAt(0).toUpperCase() + side.slice(1)
                }` as keyof React.CSSProperties,
                path,
                value: margin,
            });
        },
        [handleStyleChange, path]
    );

    return (
        <div className={styles.container}>
            <p>Margin:</p>
            <input
                id='marginTop'
                type='number'
                min='0'
                max='100'
                value={parseInt(
                    getValueFromPath(selectedElement?.styles, path)?.marginTop || '0',
                    10
                )}
                onChange={handleMarginChange('top')}
            />
            <input
                id='marginBottom'
                type='number'
                min='0'
                max='100'
                value={parseInt(
                    getValueFromPath(selectedElement?.styles, path)?.marginBottom || '0',
                    10
                )}
                onChange={handleMarginChange('bottom')}
            />
            <input
                id='marginLeft'
                type='number'
                min='0'
                max='100'
                value={parseInt(
                    getValueFromPath(selectedElement?.styles, path)?.marginLeft || '0',
                    10
                )}
                onChange={handleMarginChange('left')}
            />
            <input
                id='marginRight'
                type='number'
                min='0'
                max='100'
                value={parseInt(
                    getValueFromPath(selectedElement?.styles, path)?.marginRight || '0',
                    10
                )}
                onChange={handleMarginChange('right')}
            />
        </div>
    );
};

export default MarginChanger;
