import { useCallback, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FINAL_STAGE, STAGE_FIELDS, STAGE_TITLES } from './variables';
import { useCreateCampaign } from '../useCreateCampaign/useCreateCampaign';

export type UseCreateCampaignStage = (methods: UseFormReturn) => {
    currentStage: number;
    currentStageComplete: boolean;
    proceed: any; //() => void; // @todo sometime: add this type (use AI)
    showPrevStep: () => void;
    title: string;
    loadingSubmission: boolean;
    submissionError: string;
};

export const useCreateCampaignStage: UseCreateCampaignStage = (methods) => {
    const [currentStage, setCurrentStage] = useState<number>(1);
    const [currentStageComplete, setCurrentStageComplete] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(STAGE_TITLES[currentStage]);
    const formValues = methods.watch(); // use useWatch instead? (when inside provider)
    const {
        createCampaign,
        loading: loadingSubmission,
        error: submissionError,
    } = useCreateCampaign();

    const areFieldsValid = useCallback(
        (fieldDefinitions: { fieldName: string; nullable: boolean }[]): boolean => {
            const hasFieldErrors = fieldDefinitions.some(({ fieldName }) =>
                methods.formState.errors.hasOwnProperty(fieldName)
            );

            const hasEmptyValues = fieldDefinitions.some(({ fieldName, nullable }) => {
                const fieldValue = methods.getValues()[fieldName];
                return !nullable
                    ? Array.isArray(fieldValue)
                        ? fieldValue.length === 0
                        : !fieldValue
                    : false;
            });

            return !hasFieldErrors && !hasEmptyValues;
        },
        [methods]
    );

    const proceed = useCallback(
        async (transform: any) => {
            // Get the fields for the current stage
            const currentStageFields = STAGE_FIELDS.get(currentStage) ?? [];

            // Trigger validation for current stage's fields
            const isValid = await methods.trigger(
                currentStageFields.map((field) => field.fieldName)
            );

            if (!isValid) {
                return; // If there are validation errors, exit the function early
            }

            if (currentStageComplete && currentStage === FINAL_STAGE) {
                createCampaign(formValues);
                return;
            }

            if (areFieldsValid(currentStageFields)) {
                if (currentStage === 3) {
                    // set the html when clicking next
                    transform?.();
                }
                setCurrentStage((curStage) => curStage + 1);
            }
        },
        [areFieldsValid, currentStage, currentStageComplete, createCampaign, formValues, methods]
    );

    const showPrevStep = useCallback(() => {
        setCurrentStage((curStage) => curStage - 1);
    }, []);

    useEffect(() => {
        // @todo: debounce?
        setCurrentStageComplete(areFieldsValid(STAGE_FIELDS.get(currentStage) ?? []));
    }, [formValues, areFieldsValid, currentStage]);

    useEffect(() => {
        setTitle(STAGE_TITLES[currentStage]);
    }, [currentStage]);

    // comment in for debugging
    // console.log('formValues: ', formValues);
    // console.log('errors:', methods.formState.errors);
    // console.log('popupHtml: ', methods.watch('popupHtml'));

    return {
        currentStage,
        currentStageComplete,
        proceed,
        showPrevStep,
        title,
        loadingSubmission,
        submissionError,
    };
};
