import React, { useCallback, useContext } from 'react';
import { StyleEditorContext } from '../../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import getValueFromPath from '../../../../../../../../../../utils/HelperFunctions/getValueFromPath';
import stylePanelStyles from '../../styles.module.scss';

interface OpacityChangerProps {
    path: string[];
}

const OpacityChanger = ({ path }: OpacityChangerProps): JSX.Element => {
    const { selectedElement, handleStyleChange } = useContext(StyleEditorContext);

    const handleOpacityChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const opacity = e.target.value;
            handleStyleChange({
                style: 'opacity',
                path,
                value: opacity,
            });
        },
        [handleStyleChange, path]
    );

    return (
        <label className={stylePanelStyles['inner-label']} htmlFor='opacity'>
            Opacity:
            <input
                id='opacity'
                type='range'
                min='0'
                max='1'
                step='0.01'
                value={parseFloat(getValueFromPath(selectedElement?.styles, path)?.opacity || '1')}
                onChange={handleOpacityChange}
                className={stylePanelStyles['range']}
            />
        </label>
    );
};

export default OpacityChanger;
