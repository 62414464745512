import React, { FC, useContext, useState } from 'react';
import headerStyles from './styles.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import defaultStyles from '../../../css/default.module.scss';
import { faAngleRight, faBars, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Section } from '../../default/Areas/Areas';
import { HeaderProps, Links, HeaderLinksProps, LinkType } from './types';
import { UserContext } from '../../../contexts/UserContext/UserContext';
import { UserContextType } from '../../../contexts/UserContext/types';
// import LogoImage from './logo-transparent-sm.webp';
import LogoImage from './popup-hawk-logo-2.png';
// @todo: LogoImage has issue where going to a different page reloads the logo, causing snap on load
// ^ might be as Header keeps re-rendering as it isn't memoised correctly or something

export const Header: FC<HeaderProps> = ({ cls = '', light = false }) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const { user } = useContext<UserContextType>(UserContext);
    const headerLinks = user ? accountLinks : links;

    return (
        <Section
            clsOuter={`${headerStyles.headerOuter} ${cls} ${light ? headerStyles.light : ''}`}
            clsInner={headerStyles.headerInner}
            tag='header'
        >
            <h2>
                <Link to='/'>
                    <img src={LogoImage} alt='logo' />
                    <p>
                        Popup <i>Hawk</i>
                    </p>
                </Link>
            </h2>
            <div className={headerStyles.rightArea}>
                <FontAwesomeIcon
                    icon={menuOpen ? faTimesCircle : faBars}
                    onClick={() => setMenuOpen(!menuOpen)}
                    aria-label='menu'
                />
                <div
                    className={`${headerStyles.rightAreaInner} ${
                        menuOpen ? headerStyles.open : ''
                    }`}
                >
                    <HeaderLinks links={headerLinks} />
                    <AccountButton light={light} />
                </div>
            </div>
        </Section>
    );
};

const HeaderLinks: FC<HeaderLinksProps> = ({ links }) => (
    <ul>
        {links.map((link: LinkType) => (
            <li key={link.title}>
                <Link to={link.url}>
                    {link.title}
                    <FontAwesomeIcon icon={faAngleRight} aria-label='arrow right' />
                </Link>
            </li>
        ))}
    </ul>
);

const AccountButton = ({ light = false }: { light: boolean }): JSX.Element => {
    const navigate = useNavigate();
    const { user, setAccount } = useContext<UserContextType>(UserContext);
    const text = user ? 'Log out' : 'Login';

    const onClick = () => {
        if (user) {
            setAccount(null);
        }

        navigate('/login');
    };

    return (
        <button
            onClick={onClick}
            className={light ? defaultStyles.btnGrey : defaultStyles.btnSecondary}
        >
            {text}
        </button>
    );
};

export const links: Links = [
    {
        title: 'Home',
        url: '/',
    },
    {
        title: 'Pricing',
        url: '/pricing',
    },
    // {
    //     title: 'About',
    //     url: '/about',
    // },
];

export const accountLinks: Links = [
    {
        title: 'Home',
        url: '/',
    },
    {
        title: 'My Account',
        url: '/user',
    },
];
