import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { DndElementType } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

interface Props {
    id: DndElementType;
    icon: IconDefinition;
    name: string;
    disabled?: boolean;
}

const DraggableItem = ({ id, icon, name, disabled = false }: Props): JSX.Element | null => {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({ id });

    const style: React.CSSProperties = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        cursor: 'grab',
        pointerEvents: 'auto',
    };

    if (disabled) return null;

    return (
        <li>
            <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
                <FontAwesomeIcon icon={icon} aria-label={name} /> <h3>{name}</h3>
            </div>
        </li>
    );
};

export default DraggableItem;
