import React, { useContext } from 'react';
import { StyleEditorContext } from '../../../../../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import getValueFromPath from '../../../../../../../../../../utils/HelperFunctions/getValueFromPath';
import { DEFAULT_FONT_FAMILY } from '../../../../../../../../../../utils/global-variables';
import stylePanelStyles from '../../styles.module.scss';

interface FontFamilyChangerProps {
    path: string[];
}

const FontFamilyChanger = ({ path }: FontFamilyChangerProps): JSX.Element => {
    const { selectedElement, handleStyleChange } = useContext(StyleEditorContext);
    return (
        <label className={stylePanelStyles['inner-label']} htmlFor='fontFamily'>
            Font Family:
            <select
                id='fontFamily'
                value={
                    getValueFromPath(selectedElement?.styles, path)?.fontFamily ||
                    DEFAULT_FONT_FAMILY
                }
                onChange={(e) =>
                    handleStyleChange({
                        style: 'fontFamily',
                        path,
                        value: e.target.value,
                    })
                }
            >
                <option value='Arial'>Arial</option>
                <option value='Verdana'>Verdana</option>
                <option value='Times'>Times New Roman</option>
                {/* note: changing to 'Times New Roman' breaks the popupHtml as it adds &quot etc to html string */}
                <option value='Georgia'>Georgia</option>
                <option value='Courier'>Courier</option>
            </select>
        </label>
    );
};

export default FontFamilyChanger;
