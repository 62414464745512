import generateUUID from '../generateUUID';

/**
 * Generate a unique UUID with ph- prefix
 * Needed to prevent first character possibly being a number, otherwise styles not applied in browser (to clarify this, select an element with an id beginning with a number in the browser console and try to apply styles to it - you will see the selector is invalid)
 * @returns {string} - A unique UUID
 * @example
 * const uuid = generateUUIDWithFirstCharString();
 * console.log(uuid); // 'ph-xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
 */
const generateUUIDWithFirstCharString = () => 'ph-' + generateUUID();

export default generateUUIDWithFirstCharString;
