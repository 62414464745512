import addOnClickClose from '../addOnClickClose';
import replaceReplicaDivsWithForms from '../replaceReplicaDivsWithForms';

const finaliseHtml = (html: string): string => {
    html = addOnClickClose(html);
    html = replaceReplicaDivsWithForms(html);
    return html;
};

export default finaliseHtml;
