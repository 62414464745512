import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './style.module.scss';
import defaultStyles from '../../../../../../css/default.module.scss';
import {
    PopupTemplate,
    popupTemplates,
    PopupType,
} from '../../../../../../pages/account/private/campaigns/NewCampaign/PopupTemplates/popup-templates';
import { useFormContext, useWatch } from 'react-hook-form';
import { StyleEditorContext } from '../../../../../../contexts/StyleEditorContext/StyleEditorContext';
import { DEFAULT_CLOSE_BUTTON_STYLES } from '../../../../../../utils/global-variables';

interface Props {
    onSkip: () => void;
}

const PopupTemplatePicker = ({ onSkip }: Props): JSX.Element => {
    const [selectedTemplate, setSelectedTemplate] = useState<PopupTemplate | null>(null);
    const popupRefs = useRef<(HTMLDivElement | null)[]>([]); // Store refs for popups
    const { setValue } = useFormContext();
    const { setElements, setBodyStyles, setCloseButtonStyles } = useContext(StyleEditorContext);
    const isStoringLeads =
        useWatch({
            name: 'campaignStoreInOurDatabase',
        }) === 'true';

    // Filter templates based on isStoringLeads
    const filteredTemplates = isStoringLeads
        ? popupTemplates.filter((template) => template.types.includes(PopupType.Newsletter))
        : popupTemplates;

    useEffect(() => {
        // Create Shadow DOMs for each popup template
        filteredTemplates.forEach((template, index) => {
            const popupRef = popupRefs.current[index];
            if (popupRef && !popupRef.shadowRoot) {
                const shadowRoot = popupRef.attachShadow({ mode: 'open' });

                // Create a style element for the initial reset CSS
                const resetStyles = document.createElement('style');
                // resetStyles.textContent = '*:not(style){all:initial}';
                resetStyles.textContent =
                    '*:not(style):not(.button-container):not(.button-container *):not(.ph-text *){all:initial}';

                // Create a style element for the template CSS
                const templateStyles = document.createElement('style');
                templateStyles.textContent = template.css; // Assuming each template has a 'css' property with styles

                // Create the outer wrapper div
                const outerDiv = document.createElement('div');
                // Apply the styles directly to outerDiv without using any classes, since it's using a shadow DOM and CSS modules are inaccessible
                outerDiv.style.cssText = `
                    background-color: #fff;
                    position: relative; /* different */
                    /*
                    below is used in script but excluded here:
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    */
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-sizing: border-box;
                    flex-direction: column;
                    margin: 0;
                    padding: 0;
                    overflow: hidden;
                    width: auto;
                    /* transform: translate(-50%, -50%); used in main script only */
                    z-index: 999999999999999;
                `;

                // Create the inner div for HTML content
                const innerDiv = document.createElement('div');
                innerDiv.id = 'phci'; // Add necessary id
                innerDiv.innerHTML = template.html; // Set the inner HTML

                // Append the divs
                outerDiv.appendChild(resetStyles);
                outerDiv.appendChild(templateStyles);
                outerDiv.appendChild(innerDiv);
                shadowRoot.appendChild(outerDiv);
            }
        });
    }, [filteredTemplates]);

    const handleSelectTemplate = (template: PopupTemplate | null) => {
        if (!template) {
            setValue('popupHtml', '');
            setValue('popupCss', '');
            setSelectedTemplate(null);
            setElements([]);
            setBodyStyles({});
            setCloseButtonStyles(DEFAULT_CLOSE_BUTTON_STYLES);
            onSkip();
            return;
        }

        setSelectedTemplate(template);
        setElements(template.elements);
        setBodyStyles(template.bodyStyles);
        setCloseButtonStyles(template.closeButtonStyles);
        setValue('popupHtml', template.html);
        setValue('popupCss', template.css);
    };

    // @todo non-mvp: add filtering options (perhaps filter from types field or something)

    return (
        <>
            <p>
                You can customise the template to look however you want it to look. You can also
                skip this step and use a{' '}
                <button
                    onClick={() => handleSelectTemplate(null)}
                    className={defaultStyles.btnAsText}
                >
                    blank template
                </button>
                .
            </p>
            <div className={styles['popup-template-grid']}>
                {filteredTemplates.map((template, index) => (
                    <div key={index} className={styles['popup-template-container']}>
                        <div
                            ref={(el) => (popupRefs.current[index] = el)}
                            style={{
                                transform: 'scale(0.75)',
                                cursor: 'pointer',
                                border:
                                    selectedTemplate === template ? '2px solid #2da587' : 'none',
                                marginTop: 'auto',
                            }}
                            onClick={() => handleSelectTemplate(template)}
                        />
                        <h3 className={styles['template-name']}>{template.name}</h3>
                        <button
                            className={
                                selectedTemplate === template
                                    ? defaultStyles.btnPrimary
                                    : defaultStyles.btnGrey
                            }
                            onClick={() => handleSelectTemplate(template)}
                            type='button'
                        >
                            {selectedTemplate === template ? 'Selected' : 'Select'}
                        </button>
                    </div>
                ))}
            </div>
        </>
    );
};

export default PopupTemplatePicker;
